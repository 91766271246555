import partnerUserContextConfiguration from '@root/partners.joinroot.com/src/api/partner-user-context/partner-user-context-configuration';
import useNetworkRequest from '@root/core/src/hooks/use-network-request';
import { useCallback, useState } from '@root/vendor/react';

const configuration = partnerUserContextConfiguration();

export default function usePartnerUserContext() {
  const [partnerUserContext, setPartnerUserContext] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useNetworkRequest(configuration, useCallback((result) => {
    if (result.isSuccess()) {
      setPartnerUserContext(result.data.partnerUserContext);
      setIsLoading(false);
    }
  }, []));

  return {
    isLoading,
    partnerUserContext,
  };
}
