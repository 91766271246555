import AbstractLayout from '@root/partners.joinroot.com/src/components/abstract-layout';
import CaretLoader from '@root/core/src/components/caret-loader';
import Input, { TextTypes } from '@root/core/src/components/input';
import NextButton from '@root/partners.joinroot.com/src/components/next-button';
import PartnerLoginContainer from '@root/partners.joinroot.com/src/components/partner-login-container';
import PasswordRequirements from '@root/partners.joinroot.com/src/components/password-requirements';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useRef, useState } from '@root/vendor/react';
import resetPasswordConfiguration from '@root/partners.joinroot.com/src/api/reset-password-configuration/reset-password-configuration';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useForm from '@root/core/src/hooks/use-form';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { isRequired } from '@root/core/src/utils/validators';
import { isValidPassword } from '@root/partners.joinroot.com/src/utils/validators';
import { useHistory, useLocation } from '@root/vendor/react-router';

const validations = [
  isRequired('password'),
  isValidPassword('password'),
];

export default function ResetPassword({ onLogin }) {
  const { trackClick, trackEvent } = useAnalytics('RESET_PASSWORD');

  const [showPassword, setShowPassword] = useState(false);
  const [resetPassword, isResettingPassword] = useImperativeNetworkRequest(resetPasswordConfiguration);
  const [isError, setIsError] = useState(false);
  const [isTokenError, setIsTokenError] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const [token] = useState(params.get('token'));
  const inputRef = useRef(null);

  useEffect(() => {
    !token ? setIsTokenError(true) : null;
  }, [token]);

  const handleSubmit = async ({ password }) => {
    if (token) {
      trackClick('SIGN_IN');
      const result = await resetPassword({
        password,
        passwordResetToken: token,
      });

      if (result.isSuccess()) {
        trackEvent('RESET_SUCCESS');
        onLogin(result.data.accessToken);
      } else {
        setIsError(true);
      }
    }
  };

  const handleShowHidePassword = () => {
    trackClick('SHOW_HIDE_PASSWORD');
    setShowPassword(!showPassword);
    inputRef.current.focus();
    setTimeout(() => inputRef.current?.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length), 0);
  };

  const form = useForm({
    validations,
    initialValues: {
      password: '',
    },
  });

  let errorSummary = null;

  if (isError) {
    trackEvent('RESET_ERROR');
    errorSummary = (
      <div css={styles.errorContainer}>
        <h2 css={styles.errorHeading}>
          {'An error occurred'}
        </h2>
        <p css={styles.errorMessage}> Please try again. Please reach out to <a href={'mailto:partner-platform@joinroot.com'}> partner-platform@joinroot.com</a> if the error persists.</p>
      </div>
    );
  }

  if (isTokenError) {
    trackEvent('NO_TOKEN_ERROR');
    errorSummary = (
      <div css={styles.errorContainer}>
        <h2 css={styles.errorHeading}>
          {'An error occurred'}
        </h2>
        <p css={styles.errorMessage}>Please request a password reset link
          <span
            css={styles.errorLink}
            onClick={() => history.push('/forgot-password')}
          > here.
          </span>
        </p>
      </div>
    );
  }

  return (
    <AbstractLayout>
      {isResettingPassword ? (
        <div css={styles.loader}>
          <CaretLoader />
        </div>
      ) : (
        <PartnerLoginContainer subtext={'Create a new password'}>
          <form
            css={styles.container}
            onSubmit={form.createSubmitHandler(handleSubmit)}
          >
            <div css={styles.text}>
              <div>Make sure your password meets the required password rules:</div>
              <PasswordRequirements />
            </div>
            {errorSummary}
            <div css={styles.inputContainer}>
              <Input
                css={styles.emailInput}
                disabled={isTokenError}
                inputType={showPassword ? TextTypes.TEXT : TextTypes.PASSWORD}
                label={'Password'}
                ref={inputRef}
                {...form.getFieldProps('password')}
              />
              <div
                css={styles.showPassword}
                onClick={handleShowHidePassword}
              >
                {showPassword ? 'Hide' : 'Show'}
              </div>
            </div>
            <NextButton disabled={!form.isValid || isTokenError}>
              Sign in
            </NextButton>
          </form>
        </PartnerLoginContainer>
      )}
    </AbstractLayout>
  );
}

ResetPassword.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  loader: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 auto',
    height: 400,
    justifyContent: 'center',
  },
  inputContainer: {
    position: 'relative',
    width: '100%',
  },
  container: {
    width: '100%',
    marginBottom: 30,
  },
  passwordRequirements: {
    ...Theme.paragraph2({
      italic: true,
    }),
  },
  text: {
    ...Theme.paragraph2(),
    color: Colors.gray50(),
    paddingRight: 50,
    marginBottom: 25,
  },
  emailInput: {
    background: Colors.white(),
    border: `1px solid ${Colors.gray40()}`,
  },
  showPassword: {
    ...Theme.paragraph2(),
    position: 'absolute',
    top: 35,
    right: 15,
    zIndex: 2,
    fontSize: 12,
  },
  errorContainer: {
    border: '1px solid #E0C4C7',
    backgroundColor: Colors.lightPink(),
    padding: '15px 20px',
    marginBottom: '20px',
    ...Theme.roundedCorners(),
  },
  errorHeading: {
    ...Theme.boldCaptionTitle(),
  },
  errorMessage: {
    ...Theme.caption(),
    marginBottom: 0,
  },
  errorLink: {
    color: '#007bff',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  },
});
