export default class Colors {
  static white() {
    return '#ffffff';
  }

  static highlightGreen() {
    return '#72CAC8';
  }

  static highlightRed() {
    return '#ca7274';
  }

  static dashboardLightBlue() {
    return '#E1F1FF';
  }

  static rootOrange() {
    return '#ff5715';
  }

  static black() {
    return '#000000';
  }

  static nearBlack() {
    return '#1d1d21';
  }

  static offWhite() {
    return '#F3F3F3';
  }

  static nearWhite() {
    return '#FAFAFA';
  }

  static gray50() {
    return '#767676';
  }

  static gray30() {
    return '#dadada';
  }

  static tooltipBackground() {
    return '#3B3B3E';
  }

  static goldYellow() {
    return '#DA9514';
  }

  static primaryDashboardBlue() {
    return '#1e4e79';
  }

  static secondaryDashboardBlue() {
    return '#0071d3';
  }

  static tertiaryDashboardBlue() {
    return '#9adbff';
  }
}
