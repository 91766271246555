import Colors from '@root/partners.joinroot.com/src/utils/colors';
import Responsive from '@root/core/src/utils/responsive';
import dayjs from '@root/vendor/dayjs';
import useResize from '@root/core/src/hooks/use-resize';
import { ResponsiveBar } from '@root/vendor/@nivo/bar';
import { StyleSheet } from '@root/core/src/utils/styles';
import { useState } from '@root/vendor/react';

const ChartPlaceholder = () => {
  const checkSmallScreen = () => { return Responsive.matches(Responsive.QUERIES.lessThanMd); };
  const [isSmallScreen, setIsSmallScreen] = useState(checkSmallScreen);
  useResize({
    onResize: () => {
      setIsSmallScreen(checkSmallScreen);
    },
  });

  const today = dayjs();
  const maxYValue = 200;
  const xAxisOffset = isSmallScreen ? 20 : 10;
  const numberOfXAxisTicks = isSmallScreen ? 5 : 9;
  const next30Days = [...Array(numberOfXAxisTicks)].map((_, index) => ({
    date: today.add(-xAxisOffset * index, 'days'),
    unusedKey: maxYValue,
  })).reverse();
  const invisibleBar = 'rgb(29,29,33, 0%)';

  return (
    <div css={styles.graphContainer}>
      <ResponsiveBar
        axisBottom={{
          format: (date) => `${dayjs(date).format('MMM DD')}`,
        }}
        axisLeft={{
          tickValues: [0, 50, 100, 150, 200],
          tickSize: 0,
          tickPadding: 20,
        }}
        colors={invisibleBar}
        data={next30Days}
        enableLabel={false}
        gridYValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200]}
        indexBy={'date'}
        isInteractive={false}
        keys={['unusedKey']}
        margin={{
          top: 50,
          right: 5,
          bottom: 50,
          left: 50,
        }}
        theme={{
          axis: {
            ticks: {
              text: {
                fill: Colors.white(),
              },
            },
          },
          grid: {
            line: {
              stroke: Colors.gray50(),
              strokeWidth: 0.5,
              strokeDasharray: '2 20',
            },
          },
        }}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  graphContainer: {
    width: '100%',
    height: '100%',

    '& line[stroke-width="0.5"]:first-of-type, & line[stroke-width="0.5"]:nth-of-type(5n+6)': {
      strokeDasharray: 0,
    },
  },
  tooltipContainer: {
    color: Colors.black(),
  },
});

export default ChartPlaceholder;
