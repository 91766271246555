import Colors from '@root/partners.joinroot.com/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import { StyleSheet } from '@root/core/src/utils/styles';

const ChartTooltip = ({ input }) => {
  const {
    id, indexValue, value,
  } = input;

  return (
    <div css={styles.tooltipContainer}>
      <div
        css={styles.campaignInfo}
        data-testid={'campaign'}
      >Campaign - <span css={styles.campaignName}>{id}</span>
      </div>
      <div data-testid={'profiles'}>{value} profiles on {indexValue}</div>
    </div>
  );
};

ChartTooltip.propTypes = {
  input: PropTypes.object,
};

export default ChartTooltip;

const styles = StyleSheet.create({
  tooltipContainer: {
    background: Colors.tooltipBackground(),
    color: Colors.white(),
    padding: 10,
    margin: 0,
    fontSize: 14,
  },
  campaignInfo: {
    marginBottom: 10,
  },
  campaignName: {
    fontWeight: 'bold',
  },
});
