import CreateAccount from '@root/partners.joinroot.com/src/scenes/create-account';
import ForgotPassword from '@root/partners.joinroot.com/src/scenes/forgot-password';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import ResetLinkSent from '@root/partners.joinroot.com/src/scenes/reset-link-sent';
import ResetPassword from '@root/partners.joinroot.com/src/scenes/reset-password';
import SignIn from '@root/partners.joinroot.com/src/scenes/sign-in';
import { Redirect, Route, Switch } from '@root/core/src/components/route';

export default function PublicRouter({ onLogin }) {
  return (
    <Switch>
      <Route
        path={'/signin'}
        render={() =>
          <SignIn onLogin={onLogin} />
        }
      />
      <Route
        component={ForgotPassword}
        exact={true}
        path={'/forgot-password'}
      />
      <Route
        exact={true}
        path={'/reset-password'}
        render={() =>
          <ResetPassword onLogin={onLogin} />
        }
      />
      <Route
        component={ResetLinkSent}
        exact={true}
        path={'/reset-link-sent'}
      />
      <Route
        exact={true}
        path={'/create-account'}
        render={() => <CreateAccount onLogin={onLogin} />}
      />
      <Redirect to={'/signin'} />
    </Switch>
  );
}
PublicRouter.propTypes = {
  onLogin: PropTypes.func.isRequired,
};
