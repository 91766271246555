import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

function ClockIcon({ iconStyles }) {
  return (
    <svg
      css={iconStyles}
      fill={'none'}
      height={'15'}
      viewBox={'0 0 14 15'}
      width={'14'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={'M9.51999 4.80896L7.75005 6.702C7.76992 6.79077 7.78436 6.88339 7.78436 6.97988C7.78436 7.61861 7.30034 8.14156 6.70073 8.14156C6.29617 8.14156 5.9458 7.84439 5.75977 7.55493H4.26255C4.11265 7.55493 3.99164 7.4102 3.99164 7.25197V6.70007C3.99164 6.54184 4.11265 6.39711 4.26255 6.39711H5.75977C5.9458 6.10765 6.29437 5.81048 6.70073 5.81048C6.80729 5.81048 6.90843 5.83942 7.00776 5.8703L8.76144 3.99656C8.86619 3.88463 9.03957 3.88463 9.14433 3.99656L9.51999 4.39986C9.62654 4.50986 9.62654 4.69511 9.51999 4.80896ZM13.0888 8.13191C13.152 7.553 13.1953 7.36003 13.1953 6.96444C13.1953 3.13205 10.2785 0.0117188 6.69351 0.0117188C3.10848 0.0117188 0.195312 3.12433 0.195312 6.95672C0.195312 10.7872 2.90801 13.8998 6.70073 13.8998V13.8979C7.78436 13.894 9.14433 13.5254 10.237 12.7632L10.2677 12.74L11.0678 13.9249C11.1563 14.0542 11.2628 14.0349 11.308 13.8824L12.5036 10.1542C12.5487 10.0018 12.4621 9.87636 12.3121 9.8725L8.60793 9.87443C8.45802 9.87057 8.40745 9.97478 8.49415 10.1041L9.3141 11.3314C8.50318 11.8871 7.78256 12.1553 6.69892 12.1592V12.1631C3.98984 12.1631 1.81896 9.83005 1.81896 6.95286C1.81896 4.07568 4.00067 1.74266 6.69351 1.74266C9.38634 1.74266 11.5681 4.08532 11.5681 6.96444C11.5681 7.36196 11.5175 7.553 11.438 8.13191H13.0888Z'}
        data-testid={'clock'}
        fill={'#DA9514'}
      />
    </svg>

  );
}

ClockIcon.propTypes = {
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

export default ClockIcon;
