import CaretLoader from '@root/core/src/components/caret-loader';
import Colors from '@root/partners.joinroot.com/src/utils/colors';
import CopyableLink from '@root/partners.joinroot.com/src/components/copyable-link';
import DocumentationLink from '@root/partners.joinroot.com/src/components/documentation-link';
import PartnerUserContext from '@root/partners.joinroot.com/src/state/partner-user-context';
import PartnerUserWrapper from '@root/partners.joinroot.com/src/components/partner-user-wrapper';
import PropTypes from '@root/vendor/prop-types';
import React, { useContext } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useTermsConditions from '@root/partners.joinroot.com/src/hooks/use-terms-conditions';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';
import { pluralize } from '@root/core/src/utils/strings';

export default function Documentation({ onLogout }) {
  const { trackClick } = useAnalytics('DOCUMENTATION');

  const { isLoading, partnerUserContext } = useContext(PartnerUserContext);
  const mediaSourceUrls = partnerUserContext.mediaSourceUrls;

  const termsConditions = useTermsConditions();

  const handleResourcesClick = () => {
    trackClick('RESOURCES_LINK');
  };

  return (
    <PartnerUserWrapper onLogout={onLogout}>
      <div css={styles.container}>
        <div css={styles.content}>

          {!isLoading && mediaSourceUrls.length > 0 && (
            <>
              <div css={styles.header}>
                PARTNER IDENTIFICATION {pluralize('LINK', mediaSourceUrls.length, 'LINKS')}
              </div>
              <div css={styles.clickUrlNote}>
                Click {pluralize('this', mediaSourceUrls.length, 'a')} url to automatically copy to your clipboard
              </div>

              {
                mediaSourceUrls.map((link, i) => {
                  return (
                    <CopyableLink
                      key={i}
                      link={link}
                    />);
                })
              }
            </>
          )}

          <div css={styles.documentsSection}>
            <div css={styles.header}>
              RESOURCES
            </div>
            <DocumentationLink
              href={'https://sites.google.com/joinroot.com/root-partner-program/home?authuser=0'}
              onClick={() => handleResourcesClick()}
              text={'Root Partner Program Manual'}
            />
          </div>
          {termsConditions.isLoading ?
            <div
              css={styles.documentsSection}
              data-testid={'loader'}
            ><CaretLoader />
            </div> :
            <div css={styles.documentsSection}>
              <div css={styles.header}>
                ACCEPTED DOCUMENTS
              </div>
              <DocumentationLink
                href={`${environment.websiteBaseUrl}/affiliates/privacy-policy/`}
                onClick={() => trackClick('PRIVACY_POLICY')}
                text={'Root Partner Program Privacy Policy'}
              />
              {termsConditions.isError ?
                <div
                  css={styles.termsConditionsError}
                  data-testid={'error'}
                >
                  We’re having trouble fetching your Terms and Conditions at the moment. Contact
                  <a mailto={'partner-platform@joinroot.com'}>{' '}partner-platform@joinroot.com</a> to access them.
                </div> :
                <DocumentationLink
                  href={termsConditions.termsConditionsUrl}
                  onClick={() => trackClick('TERMS_CONDITIONS')}
                  text={'Root Partner Platform Terms of Use'}
                />
              }
            </div>
          }
        </div>
      </div>
    </PartnerUserWrapper>
  );
}

Documentation.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.offWhite(),
    height: '94vh',
  },
  content: {
    width: 410,
    padding: 10,
    marginTop: 78,
    display: 'flex',
    flexDirection: 'column',
    ...Responsive.lessThanSm({
      width: '100%',
      marginTop: 20,
    }),
  },
  clickUrlNote: {
    fontSize: 14,
  },
  link: {
    fontSize: 16,
    marginBottom: 20,
    cursor: 'pointer',
    ...Theme.paragraph1(),
    color: Colors.rootOrange(),
    textDecoration: 'underline',
  },
  linkLoadingIndicator: {
    fontSize: 12,
  },
  documentsSection: {
    marginTop: 100,
  },
  header: {
    fontSize: 16,
    color: Colors.nearBlack(),
    fontWeight: 600,
    paddingBottom: 15,
    borderBottom: `1px solid ${Colors.gray30()}`,
    marginBottom: 20,
  },
  termsConditionsError: {
    paddingTop: 10,
  },
});
