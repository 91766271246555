import PropTypes from '@root/vendor/prop-types';
import { StyleSheet } from '@root/core/src/utils/styles';
import { keyframes } from '@root/vendor/emotion';

const SkeletonLoaderBar = ({ testID }) => {
  return (
    <div
      css={styles.loader}
      data-testid={testID}
    />
  );
};

export default SkeletonLoaderBar;

const loadingAnimation = keyframes({
  '0%': {
    backgroundPosition: '10% 0%',
  },
  '50%': {
    backgroundPosition: '100% 0%',
  },
  '100%': {
    backgroundPosition: '10% 0',
  },
});

SkeletonLoaderBar.propTypes = {
  testID: PropTypes.string,
};

const styles = StyleSheet.create({
  loader: {
    width: '100%',
    height: 10,
    background: 'linear-gradient(89.94deg, #E5E5E5 -7.78%, rgba(196, 196, 196, 0.36) 64.28%, rgba(196, 196, 196, 0) 113.76%)',
    backgroundSize: '200% 200%',
    borderRadius: 15,
    animation: `${loadingAnimation} 3s infinite`,
  },
});
