import partnerCampaignPerformanceConfiguration from '@root/partners.joinroot.com/src/api/partner-campaign-performance-configuration/partner-campaign-performance-configuration';
import useNetworkRequest from '@root/core/src/hooks/use-network-request';
import { useMemo } from '@root/vendor/react';

const useCampaignPerformance = (callback) => {
  const configuration = useMemo(partnerCampaignPerformanceConfiguration, [partnerCampaignPerformanceConfiguration]);

  return useNetworkRequest(configuration, callback);
};

export default useCampaignPerformance;
