import BreakdownTables from '@root/partners.joinroot.com/src/components/breakdown-tables';
import CampaignChart from '@root/partners.joinroot.com/src/components/bar-chart/campaign-chart';
import CampaignPerformanceTable from '@root/partners.joinroot.com/src/components/campaign-performance-table';
import ChartPlaceholder from '@root/partners.joinroot.com/src/components/bar-chart/chart-placeholder';
import Colors from '@root/partners.joinroot.com/src/utils/colors';
import EarningsHeroItem from '@root/partners.joinroot.com/src/components/earnings-hero-item';
import HeroItemSkeletonLoader from '@root/partners.joinroot.com/src/components/hero-item-skeleton-loader';
import Money from '@root/core/src/models/money';
import PaidEventHeroItem from '@root/partners.joinroot.com/src/components/paid-event-hero-item';
import PartnerUserContext from '@root/partners.joinroot.com/src/state/partner-user-context';
import PartnerUserWrapper from '@root/partners.joinroot.com/src/components/partner-user-wrapper';
import PreviousMonthTable from '@root/partners.joinroot.com/src/components/previous-month-table';
import PropTypes from '@root/vendor/prop-types';
import PublisherEffectivenessTable from '@root/partners.joinroot.com/src/components/publisher-effectiveness-table';
import React, { useCallback, useContext, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import RqiHeroItem from '@root/partners.joinroot.com/src/components/rqi-hero-item';
import SkeletonLoaderBar from '@root/partners.joinroot.com/src/components/skeleton-loader-bar';
import SkeletonLoaderTable from '@root/partners.joinroot.com/src/components/skeleton-loader-table';
import SubsiteEffectivenessTable from '@root/partners.joinroot.com/src/components/subsite-effectiveness-table';
import dayjs from '@root/vendor/dayjs';
import environment from '@root/core/src/utils/environment';
import useCampaignPerformance from '@root/partners.joinroot.com/src/hooks/use-campaign-performance';
import usePartnerAttributedEarnings from '@root/partners.joinroot.com/src/hooks/use-partner-attributed-earnings';
import usePartnerDailyEvents from '@root/partners.joinroot.com/src/hooks/use-partner-daily_events';
import { DEFAULT_DATA_LENGTH, createDataForBarChart, legendValues, makeLoadingDataWave } from '@root/partners.joinroot.com/src/utils/bar-chart-utils';
import { PricingTypes } from '@root/partners-common/src/models/pricing-rule';
import { StyleSheet } from '@root/core/src/utils/styles';
import { bold, semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function Dashboard({ onLogout }) {
  const { isLoading: isLoadingUserContext, partnerUserContext } = useContext(PartnerUserContext);
  const [campaignAdData, setCampaignAdData] = useState([]);
  const {
    dataLoadAuditTimestamp,
    paymentEvents,
    pricingType,
  } = partnerUserContext || {};
  const [dailyEvents, setDailyEvents] = useState([]);
  const [noDailyEventsFound, setNoDailyEventsFound] = useState(false);
  const isLoadingDailyEvents = usePartnerDailyEvents(useCallback((result) => {
    if (!result.data.usePartnerDailyEvents) {
      setNoDailyEventsFound(true);
    }
    setDailyEvents(result.data.partnerDailyEvents);
  }, []));

  const [partnerAttributedEarnings, setPartnerAttributedEarnings] = useState([]);
  const isLoadingPartnerEarnings = usePartnerAttributedEarnings(useCallback((result) => {
    const resultData = result.data?.partnerAttributedEarnings;

    if (resultData) {
      resultData.sort((a, b) => {
        return a.earnedMonth < b.earnedMonth ? 1 : -1;
      });

      const formattedData = resultData.map((item) => {
        return (
          {
            ...item,
            formattedEarning: `${Money.fromDollars(item.earning).formattedDollarsAndCents()}`,
          }
        );
      });
      setPartnerAttributedEarnings(formattedData);
    }
  }, []));

  if (isLoadingDailyEvents && !noDailyEventsFound && dailyEvents.length === 0) {
    setDailyEvents(makeLoadingDataWave(DEFAULT_DATA_LENGTH));
  }

  const latestEarnings = partnerAttributedEarnings && partnerAttributedEarnings[0];
  const previousEarnings = partnerAttributedEarnings?.length > 1 && partnerAttributedEarnings[1];
  const paidEventMonthlyDifference = previousEarnings ? latestEarnings.attributedEarnedEventCount - previousEarnings.attributedEarnedEventCount : 0;
  const isLoadingCampaignPerformance = useCampaignPerformance(useCallback((result) => {
    const resultData = result.data?.campaignData;
    if (resultData) {
      resultData.sort((a, b) => {
        if (a.attributedCampaign === b.attributedCampaign) {
          return a.attributedAd < b.attributedAd ? -1 : 1;
        }
        return a.attributedCampaign < b.attributedCampaign ? -1 : 1;
      });
    }
    setCampaignAdData(resultData);
  }, []));

  const displayEstimatedEarningOrModeCta = () => {
    if (pricingType === PricingTypes.MULTIPLIER_KEY && environment.hidePartnerEarningsEstimate) {
      return (
        <div css={styles.heroItem}>
          <div css={styles.modeWrapper}>
            <div css={styles.modeSpacingContainer}>
              <div css={styles.modeTitleContainer}>
                Please see your Mode dashboard for details regarding estimated earnings.
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div css={styles.heroItem}>
        {isLoadingPartnerEarnings || isLoadingUserContext ?
          <div css={styles.heroItemLoader}>
            <HeroItemSkeletonLoader
              displayFootnote
              aria-label={'estimated earnings'}
              prefix={'$'}
              subtitle={'*POTENTIAL EARNINGS THIS MONTH'}
            />
          </div> :
          <EarningsHeroItem
            paymentEvents={paymentEvents}
            potentialEarnings={latestEarnings?.earning}
            pricingType={pricingType}
          />
        }
      </div>
    );
  };

  return (
    <PartnerUserWrapper onLogout={onLogout}>
      <div css={styles.main}>
        <div css={styles.infoContainer}>
          <div css={styles.dateUpdatedContainer}>all data is up to date as of:
            {isLoadingUserContext ?
              <div css={styles.skeletonLoaderBar}>
                <SkeletonLoaderBar />
              </div> :
              <span css={styles.date}>{dayjs(dataLoadAuditTimestamp).format('MMMM D YYYY')}</span>
            }
          </div>
          <div css={styles.topSection}>
            <div css={styles.heroContainer}>
              {displayEstimatedEarningOrModeCta()}
              <div css={styles.heroItem}>
                {isLoadingPartnerEarnings || isLoadingUserContext ?
                  <div css={styles.heroItemLoader}>
                    <HeroItemSkeletonLoader
                      aria-label={'total profiles'}
                      subtitle={'TOTAL PROFILES THIS MONTH'}
                    />
                  </div> :
                  <PaidEventHeroItem
                    paidEventCount={latestEarnings?.attributedEarnedEventCount}
                    paidEventMonthlyDifference={paidEventMonthlyDifference}
                    paymentEvents={paymentEvents}
                    pricingType={pricingType}
                  />
                }
              </div>
              {pricingType === PricingTypes.MULTIPLIER_KEY && !environment.hidePartnerEarningsEstimate &&
                <div css={styles.heroItem}>
                  {isLoadingPartnerEarnings || isLoadingUserContext ?
                    <div css={styles.heroItemLoader}>
                      <HeroItemSkeletonLoader
                        aria-label={'root quality indicator'}
                        subtitle={'Root Quality Indicator'}
                      />
                    </div> :
                    <RqiHeroItem rqi={latestEarnings?.rootQualityIndicator} />
                  }
                </div>
              }
            </div>
            <div
              css={styles.campaignInfo}
              data-testid={'campaign-chart-container'}
            >
              <div css={styles.campaignInfo}>
                <div css={styles.campaignGraphTitle}>Profiles per day per campaign</div>
                <div css={styles.campaignGraphContainer}>
                  {
                    dailyEvents && dailyEvents.length > 0 ? (
                      <CampaignChart
                        data={createDataForBarChart(dailyEvents, DEFAULT_DATA_LENGTH)}
                        isLoading={isLoadingDailyEvents}
                        legendValues={legendValues(dailyEvents)}
                      />
                    ) : (
                      <div css={styles.noDataChartContainer}>
                        <div css={styles.noDataChartText}>Sorry, no data to display here at the moment</div>
                        <ChartPlaceholder />
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div css={styles.bodyWrapper}>
          <div css={styles.body}>
            <div data-testid={'previous-month-table'}>
              {isLoadingPartnerEarnings || isLoadingUserContext ?
                <SkeletonLoaderTable
                  testId={'previous-month-skeleton-loader-table'}
                  title={'Past Month Performance'}
                /> :
                <PreviousMonthTable
                  partnerAttributedEarnings={partnerAttributedEarnings}
                  paymentEvents={paymentEvents}
                  pricingType={pricingType}
                />
              }
            </div>
            <div
              css={styles.campaignPerformanceTable}
              data-testid={'campaign-performance-table'}
            >
              {isLoadingCampaignPerformance ?
                <SkeletonLoaderTable
                  testId={'campaign-performance-skeleton-loader-table'}
                  title={'Campaign Performance'}
                /> :
                <CampaignPerformanceTable campaignAdData={campaignAdData} />
              }
            </div>
            {pricingType === PricingTypes.MULTIPLIER_KEY &&
              <>
                <div css={styles.adjacentTableContainer} >
                  <div css={styles.adjacentTable}>
                    <PublisherEffectivenessTable />
                  </div>
                  <div css={styles.adjacentTable}>
                    <SubsiteEffectivenessTable css={styles.adjacentTable} />
                  </div>
                </div>
                <BreakdownTables />
              </>
            }
          </div>
        </div>
      </div>
    </PartnerUserWrapper>
  );
}

Dashboard.propTypes = {
  onLogout: PropTypes.func,
};

const styles = StyleSheet.create({
  dateUpdatedContainer: {
    color: Colors.dashboardLightBlue(),
    textTransform: 'uppercase',
    fontSize: 16,
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 20,
    ...semiBold(),
    ...Responsive.lessThanMd({
      width: '95%',
    }),
  },
  date: {
    ...bold(),
    paddingLeft: 4,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: Colors.nearWhite(),
  },
  topSection: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '5%',
    ...Responsive.lessThanMd({
      width: '95%',
    }),
  },
  heroContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    ...Responsive.lessThanMd({
      width: '90%',
      display: 'block',
      paddingTop: 20,
    }),
  },
  infoContainer: {
    width: '100%',
    backgroundColor: Colors.nearBlack(),
  },
  heroItem: {
    width: 500,
    ...Responsive.lessThanMd({
      marginBottom: 20,
      width: '100%',
    }),
  },
  previousMonthTableWrapper: {
    backgroundColor: Colors.white(),
    color: Colors.black(),
    display: 'flex',
    width: '80%',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '-5%',
  },
  body: {
    color: Colors.black(),
    display: 'flex',
    width: '80%',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 50,
    marginTop: '-5%',
    position: 'relative',
    zIndex: 2,
    ...Responsive.lessThanSm({
      width: '95%',
    }),
  },
  bodyWrapper: {
    backgroundColor: Colors.nearWhite(),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  campaignPerformanceTable: {
    marginTop: 15,
  },
  campaignInfo: {
    marginTop: 50,
  },
  campaignGraphTitle: {
    fontSize: 16,
    marginBottom: 20,
    textTransform: 'uppercase',
    color: Colors.dashboardLightBlue(),
    marginTop: 5,
    fontWeight: 600,
    ...Responsive.lessThanMd({
      fontSize: 14,
    }),
  },
  campaignGraphContainer: {
    width: '100%',
    height: 400,
  },
  heroItemLoader: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 50,
  },
  campaignChartLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 400,
    width: '100%',
  },
  noDataChartContainer: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  noDataChartText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    fontSize: 16,
    ...Responsive.lessThanMd({
      fontSize: 14,
    }),
    textAlign: 'center',
    color: Colors.dashboardLightBlue(),
  },
  skeletonLoaderBar: {
    width: 100,
    marginLeft: 5,
  },
  adjacentTableContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  adjacentTable: {
    width: '49%',
  },
  footnote: {
    color: Colors.gray50(),
    fontSize: '12px',
    marginTop: '20px',
    textAlign: 'center',
  },
  modeWrapper: {
    paddingTop: 20,
    paddingBottom: 20,

  },
  modeSpacingContainer: {
    color: Colors.dashboardLightBlue(),
    paddingRight: 20,
  },
  modeTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    textTransform: 'uppercase',
    fontWeight: 600,
    ...Responsive.lessThanMd({
      fontSize: 14,
    }),
  },
});
