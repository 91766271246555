import Colors from '@root/partners.joinroot.com/src/utils/colors';
import PaginatedTable from '@root/internal-dashboards-core/src/components/paginated-table';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import Theme from '@root/brand/src/utils/theme';
import { StyleSheet } from '@root/core/src/utils/styles';

const DashboardTable = ({
  columns, data, title, csvNamePrefix, styleOverrides, csvExportable = true,
}) => (
  <div css={styles.tableContainer}>
    <PaginatedTable
      columns={columns}
      csvExportable={csvExportable}
      csvNamePrefix={csvNamePrefix}
      data={data}
      pageSize={12}
      styleOverrides={{
        ...styles,
        ...styleOverrides,
      }}
      title={title}
    />
  </div>
);

DashboardTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      sortType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
    })
  ).isRequired,
  csvExportable: PropTypes.bool,
  csvNamePrefix: PropTypes.string,
  data: PropTypes.array.isRequired,
  styleOverrides: PropTypes.object,
  title: PropTypes.string,
};

export default DashboardTable;

const styles = StyleSheet.create({
  tableHeaderGroup: {
    ...Responsive.lessThanSm({
      paddingLeft: 5,
    }),
  },
  cell: {
    overflowWrap: 'break-word',
    color: Colors.black(),
    ...Responsive.lessThanSm({
      fontSize: 12,
      paddingLeft: 5,
    }),
  },
  headerItem: {
    border: 'none',
    color: Colors.black(),
  },
  headerItemContainer: {
    ...Theme.paragraph2({
      bold: true,
    }),
    color: Colors.black(),
    ...Responsive.lessThanSm({
      fontSize: 12,
      paddingLeft: 5,
    }),
  },
  row: {
    ...Theme.paragraph2(),
    width: 'calc(100% + 16px)',
  },
  titleContent: {
    ...Theme.paragraph2({
      bold: true,
    }),
    textTransform: 'uppercase',
    color: Colors.black(),
  },
  tableContainer: {
    backgroundColor: Colors.white(),
  },
});
