import Colors from '@root/brand/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import ResponsiveHeaderLogo from '@root/partners.joinroot.com/src/components/responsive-header-logo';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet } from '@root/core/src/utils/styles';
import { bold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function AbstractLayout({ children }) {
  const { trackClick } = useAnalytics('ABSTRACT_LAYOUT', false);
  const [isFirefox] = useState(navigator.userAgent.toLowerCase().indexOf('firefox') > -1);

  const handleJoinRootLinkClick = () => {
    trackClick('ROOT_HOME');
    window.open(environment.websiteBaseUrl, '_self');
  };

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <ResponsiveHeaderLogo
          logoColor={Colors.rootOrange()}
          textStyling={{
            color: Colors.black(),
          }}
        />
      </div>
      <div css={[styles.background, isFirefox ? styles.fireFoxStyling : '']} />
      <div css={styles.content}>
        {children}
      </div>
      <div css={styles.footer}>
        <div
          css={styles.disclaimer}
          data-testid={'disclaimer'}
        >
          <p css={styles.learnMore}>
            To learn more about Root Insurance visit{' '}
            <a
              css={styles.joinRootLink}
              data-testid={'learn-more'}
              onClick={handleJoinRootLinkClick}
            >
              joinroot.com
            </a>
          </p>
          <p>
            Copyright ROOT 2020. ROOT is a registered servicemark of Root Insurance Company, Columbus, OH. <b>Disclaimer for quotes:</b> WE RESERVE THE RIGHT TO REFUSE TO QUOTE ANY INDIVIDUAL A PREMIUM RATE FOR THE INSURANCE ADVERTISED HEREIN. <b>Disclaimer for coverage:</b> Coverage is available in the event of a covered loss. Exclusions may apply. Not available in all states. <b>Disclaimer for savings:</b> Based on savings reported by actual customers who purchased a new Root policy between September 2018 - August 2019; changes in coverage levels not evaluated. <b>For California residents:</b> Telematics is not used and resulting represented savings are not applicable. Referral program not applicable. Roadside Assistance purchased as separate coverage. Visit joinroot.com/califaq for more information.
          </p>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: Colors.nearWhite(),
  },
  background: {
    position: 'absolute',
    bottom: '10%',
    top: '15%',
    left: '30%',
    right: '35%',
    backgroundImage: 'radial-gradient(108.35% 126.87% at 92.09% 2.19%, #1170FF 0%, #41FFBB 37.4%, #FFE500 95.3%)',
    filter: 'blur(120px)',
    transform: 'rotate(-105deg) translateZ(0)',
    opacity: .4,
    ...Responsive.lessThanSm({
      width: '100%',
      position: 'relative',
    }),
  },
  fireFoxStyling: {
    opacity: .2,
    borderRadius: 170,
  },
  header: {
    padding: 10,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    ...Responsive.lessThanSm({
      position: 'relative',
      padding: 18,
    }),
  },
  disclaimer: {
    color: Colors.gray50(),
    margin: '0 auto',
    width: 739,
    fontSize: 9,
    '& b': {
      ...bold(),
    },
    ...Responsive.lessThanMd({
      width: '90%',
    }),
  },
  learnMore: {
    fontSize: 12,
    marginBottom: 8,
  },
  joinRootLink: {
    cursor: 'pointer',
  },
});

AbstractLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
