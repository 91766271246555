import NavMenuButton from '@root/core/src/components/nav/nav-menu-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import ResponsiveHeaderLogo from '@root/partners.joinroot.com/src/components/responsive-header-logo';
import TitledDropdown from '@root/internal-dashboards-core/src/components/titled-dropdown';
import useEscOrClickAwayToClose from '@root/internal-dashboards-core/src/hooks/use-esc-or-click-away-to-close';
import useResize from '@root/core/src/hooks/use-resize';
import { BUTTON_SIZE_SMALL, Button } from '@root/core/src/components/button';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { IconLock } from '@root/internal-dashboards-core/src/components/icons/lock-icon';

export default function UserDashboardHeader({ username = '', onLogout }) {
  const checkSmallScreen = () => { return window.innerWidth < Responsive.BREAKPOINTS.md; };
  const [isSmallScreen, setIsSmallScreen] = useState(checkSmallScreen);
  const [isNavOpen, setNavState] = useState(false);

  const {
    ref, isComponentVisible, setIsComponentVisible,
  } = useEscOrClickAwayToClose();

  useResize({
    onResize: () => {
      setIsSmallScreen(checkSmallScreen);
    },
  });

  const handleSignoutClick = () => {
    onLogout();
  };

  useEffect(() => {
    if (!isSmallScreen) {
      setNavState(false);
    }
  }, [isSmallScreen]);

  return (
    <div
      css={styles.container}
    >
      <div
        className={`${isNavOpen ? 'nav-is-open' : ''}`}
        css={styles.menuItems}
      >
        <ResponsiveHeaderLogo
          logoColor={isSmallScreen ? Colors.white() : Colors.gray50()}
          textStyling={{
            color: Colors.gray50(),
          }}
        />

        <div
          css={[styles.linksContainer, isSmallScreen && styles.linksMobile, isNavOpen && styles.linksTransition]}
        >
          {
            isSmallScreen && (
              <div css={styles.linkMobileProfile}>
                <div css={styles.profileMobile}>
                  {username}
                  <Button
                    css={styles.button}
                    onClick={handleSignoutClick}
                    size={BUTTON_SIZE_SMALL}
                  >
                    Sign Out
                  </Button>
                </div>
              </div>
            )
          }
        </div>

        <div css={styles.menuIconWrapper}>
          <NavMenuButton
            toggleNavState={() => setNavState(!isNavOpen)}
          />
        </div>

        {
          !isSmallScreen && (
            <div
              css={styles.profile}
              onClick={() => setIsComponentVisible(!isComponentVisible)}
              ref={ref}
            >
              <TitledDropdown
                buttonStyle={styles.profileDropdown}
                chevronStyle={styles.profileDropdownChevron}
                closedChevronColor={Colors.gray10()}
                contents={[{
                  icon: (
                    <IconLock
                      color={Colors.nearWhite()}
                      iconStyles={{
                        marginRight: 10,
                      }}
                    />
                  ),
                  label: 'Sign Out',
                  value: 'SIGN_OUT',
                }]}
                contentStyle={styles.profileDropdownOpen}
                iconColor={Colors.nearWhite()}
                isOpen={isComponentVisible}
                onClick={handleSignoutClick}
                openButtonStyle={{
                  color: Colors.nearWhite(),
                }}
                openStyle={{
                  right: 0,
                }}
                title={username}
              />
            </div>
          )
        }
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    background: Colors.nearBlack(),
    color: Colors.white(),
    height: 54,
  },
  menuItems: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  linksContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'absolute',
    top: -4,
    color: Colors.lightBlue(),
    ...Responsive.lessThanMd({
      justifyContent: 'flex-start',
    }),
  },
  menuIconWrapper: {
    marginLeft: 'auto',
    paddingRight: 16,
  },
  linksMobile: {
    paddingTop: 30,
    marginTop: 54,
    flexDirection: 'column',
    backgroundColor: Colors.nearBlack(),
    height: '0%',
    opacity: 0,
    zIndex: 0,
    transition: 'height .2s linear, opacity .2s linear',
  },
  linksTransition: {
    height: '100%',
    opacity: 1,
    zIndex: 10,
  },
  linkMobileProfile: {
    display: 'flex',
    flex: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  profile: {
    color: Colors.nearWhite(),
    paddingRight: 10,
  },
  profileMobile: {
    color: Colors.nearWhite(),
    textAlign: 'center',
  },
  profileDropdown: {
    color: Colors.lightBlue(),
    backgroundColor: 'transparent',
    border: 'none',
    width: 'auto',
  },
  profileDropdownChevron: {
    marginLeft: 12,
  },
  profileDropdownOpen: {
    backgroundColor: Colors.nearBlack(),
    borderTop: `1px solid ${Colors.nearBlack()}`,
    boxShadow: '-8px 14px 25px -5px rgba(0, 0, 0, 0.4)',
    paddingLeft: 16,
    height: 50,
    width: 175,
    ':hover': {
      color: Colors.nearWhite(),
      backgroundColor: Colors.nearBlack(),
    },
  },
  button: {
    border: `1px solid ${Colors.gray60()}`,
    borderRadius: 2,
    backgroundColor: 'transparent',
  },
});

UserDashboardHeader.propTypes = {
  onLogout: PropTypes.func.isRequired,
  username: PropTypes.string,
};
