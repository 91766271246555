import ClockIcon from '@root/core/src/components/icons/clock';
import Colors from '@root/partners.joinroot.com/src/utils/colors';
import DashboardTable from '@root/partners.joinroot.com/src/components/dashboard-table';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { EventTypes } from '@root/partners-common/src/models/partner-payment-event';
import { PricingTypes } from '@root/partners-common/src/models/pricing-rule';
import { StyleSheet } from '@root/core/src/utils/styles';

function PendingCell({
  row,
  value,
}) {
  if (row.original.finalized === true) {
    return value;
  }

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div>{value}</div>
      <div
        style={{
          marginLeft: 20,
        }}
      >
        <ClockIcon />
      </div>
      <div css={cellStyles.pendingContainer}>
        Pending
      </div>
    </div>
  );
}

const cellStyles = StyleSheet.create({
  pendingContainer: {
    color: Colors.goldYellow(),
    fontStyle: 'italic',
    marginLeft: 10,
    ...Responsive.lessThanSm({
      display: 'none',
    }),
  },
});

PendingCell.propTypes = {
  row: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

function PreviousMonthTable({
  partnerAttributedEarnings,
  paymentEvents,
  pricingType,
}) {
  const previousEarningsColumns = [];
  const earnedMonthColumn = {
    Header: 'Month',
    accessor: 'earnedMonth',
  };

  const earningColumn = {
    Header: 'Earning',
    accessor: 'formattedEarning',
    Cell: PendingCell,
  };

  previousEarningsColumns.push(earnedMonthColumn);

  const eventColumns = (paymentEvents || []).map(({ eventType }) => {
    return {
      Header: `${EventTypes.PLURAL_MAPPING[eventType]}`,
      accessor: paymentEvents.length === 1 ? 'attributedEarnedEventCount' : `${EventTypes.ACCESSOR_MAPPING[eventType]}`,
    };
  });

  previousEarningsColumns.push(...eventColumns);

  previousEarningsColumns.push(earningColumn);

  if (pricingType === PricingTypes.MULTIPLIER_KEY) {
    previousEarningsColumns.push({
      Header: 'RQI',
      accessor: 'rootQualityIndicator',
    });
    previousEarningsColumns.push({
      Header: 'CPP',
      accessor: 'costPerProfile',
    });
  }

  if (pricingType !== PricingTypes.MULTIPLIER_KEY) {
    partnerAttributedEarnings = partnerAttributedEarnings.map((earning) => {
      const cleanedEarning = {
        ...earning,
      };
      delete cleanedEarning.rootQualityIndicator;
      return cleanedEarning;
    });
  }

  return (
    <DashboardTable
      columns={previousEarningsColumns}
      csvExportable={false}
      csvNamePrefix={'root_past_month_performance_table_export'}
      data={partnerAttributedEarnings}
      title={'Past Month Performance'}
    />
  );
}

PreviousMonthTable.propTypes = {
  partnerAttributedEarnings: PropTypes.array,
  paymentEvents: PropTypes.array,
  pricingType: PropTypes.string,
};

export default React.memo(PreviousMonthTable);
