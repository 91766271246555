import KeyCode from '@root/core/src/utils/keycode';
import { useEffect, useRef, useState } from '@root/vendor/react';

export default function useEscOrClickAwayToClose() {
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsComponentVisible(false);
      }
    };

    const handleKeydown = (event) => {
      if (event.keyCode === KeyCode.KEYCODES.ESC) {
        setIsComponentVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [setIsComponentVisible]);

  return {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  };
}
