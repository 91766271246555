export class PricingTypes {
  static FIXED_KEY = 'fixed';
  static MULTIPLIER_KEY = 'multiplier';
  static COMMISSION_KEY = 'commission';
  static NO_PAYMENT_KEY = 'no_payment';

  static MAPPING = Object.freeze({
    [PricingTypes.FIXED_KEY]: 'Fixed',
    [PricingTypes.MULTIPLIER_KEY]: 'Multiplier',
    [PricingTypes.COMMISSION_KEY]: 'Commission',
    [PricingTypes.NO_PAYMENT_KEY]: 'No Payment',
  });

  static FIXED = PricingTypes.MAPPING[PricingTypes.FIXED_KEY];
  static MULTIPLIER = PricingTypes.MAPPING[PricingTypes.MULTIPLIER_KEY];
  static COMMISSION = PricingTypes.MAPPING[PricingTypes.COMMISSION_KEY];
  static NO_PAYMENT = PricingTypes.MAPPING[PricingTypes.NO_PAYMENT_KEY];

  static INACTIVE_TYPES = [
    PricingTypes.COMMISSION_KEY,
  ];

  static OPTIONS = Object.entries(PricingTypes.MAPPING)
    .filter(([key]) => !PricingTypes.INACTIVE_TYPES.includes(key))
    .map(([value, label]) => ({
      value,
      label,
    }));
}
