import AbstractLayout from '@root/partners.joinroot.com/src/components/abstract-layout';
import CaretLoader from '@root/core/src/components/caret-loader';
import Input, { TextTypes } from '@root/core/src/components/input';
import NextButton from '@root/partners.joinroot.com/src/components/next-button';
import PartnerLoginContainer from '@root/partners.joinroot.com/src/components/partner-login-container';
import PasswordRequirements from '@root/partners.joinroot.com/src/components/password-requirements';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useMemo, useState } from '@root/vendor/react';
import emailFromTokenConfiguration from '@root/partners.joinroot.com/src/api/email-from-token/email-from-token-configuration';
import tokenSignupConfiguration from '@root/partners.joinroot.com/src/api/token-sign-up/token-sign-up-configuration';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useForm from '@root/core/src/hooks/use-form';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import useNetworkRequest from '@root/core/src/hooks/use-network-request';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { isRequired } from '@root/core/src/utils/validators';
import { isValidPassword } from '@root/partners.joinroot.com/src/utils/validators';
import { useHistory, useLocation } from '@root/vendor/react-router';

const validations = [
  isRequired('password'),
  isValidPassword('password'),
];
export default function CreateAccount({ onLogin }) {
  const { trackEvent } = useAnalytics('CREATE_ACCOUNT');
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const [token] = useState(params.get('token'));
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState(null);
  const [signUp, isSigningUp] = useImperativeNetworkRequest(tokenSignupConfiguration);

  const configuration = useMemo(() => emailFromTokenConfiguration(token), [token]);

  const isLoading = useNetworkRequest(configuration, useCallback((result) => {
    setIsError(false);
    if (result.isSuccess()) {
      trackEvent('LOOKUP_SUCCESS');
      setEmail(result.data.email);
    } else {
      trackEvent('LOOKUP_ERROR');
      history.push('/signin');
    }
  }, [history, trackEvent]));

  const form = useForm({
    validations,
    initialValues: {
      password: '',
    },
  });

  const createAccount = async () => {
    const password = form.getFieldProps('password').value;

    if (password && token) {
      const result = await signUp({
        token,
        password,
      });

      if (result.isSuccess()) {
        trackEvent('SIGNUP_SUCCESS');
        onLogin(result.data.accessToken);
      } else {
        trackEvent('SIGNUP_ERROR');
        setIsError(true);
      }
    }
  };

  let errorSummary = null;

  if (isError) {
    errorSummary = (
      <div css={styles.errorContainer}>
        <h2 css={styles.errorHeading}>
          {'An error occurred'}
        </h2>
        <p css={styles.errorMessage}>{'Please try again.'}</p>
      </div>
    );
  }
  return (
    <AbstractLayout>
      {
        isLoading || isSigningUp ? (
          <div css={styles.loader}>
            <CaretLoader />
          </div>
        ) : (
          <PartnerLoginContainer subtext={'Create your account'}>
            <form
              css={styles.main}
              onSubmit={form.createSubmitHandler(createAccount)}
            >
              <p css={styles.getStarted}> <span css={styles.boldGetStarted}>All we need is</span> a password to get you started</p>
              {errorSummary}
              <div css={styles.emailContainer}>
                {email}
              </div>
              <Input
                {...form.getFieldProps('password')}
                css={styles.input}
                id={'password'}
                inputType={TextTypes.PASSWORD}
                label={'Password'}
              />
              <div css={styles.passwordInstructions}>
                <PasswordRequirements />
              </div>
              <NextButton disabled={!form.isValid}>
                Create
              </NextButton>
            </form>
          </PartnerLoginContainer>
        )
      }
    </AbstractLayout>
  );
}

CreateAccount.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  loader: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 auto',
    height: 400,
    justifyContent: 'center',
  },
  main: {
    width: '100%',
    marginBottom: 30,
  },
  getStarted: {
    ...Theme.paragraph2(),
    width: '70%',
    marginBottom: 30,
  },
  boldGetStarted: {
    ...Theme.paragraph2({
      bold: true,
    }),
  },
  passwordInstructions: {
    marginTop: 15,
    marginLeft: 15,
  },
  emailContainer: {
    paddingBottom: 10,
    ...Theme.paragraph1(),
  },
  input: {
    background: Colors.white(),
    border: `1px solid ${Colors.gray40()}`,
  },
  errorContainer: {
    border: '1px solid #E0C4C7',
    backgroundColor: Colors.lightPink(),
    padding: '15px 20px',
    marginBottom: '20px',
    ...Theme.roundedCorners(),
  },
  errorHeading: {
    ...Theme.boldCaptionTitle(),
  },
  errorMessage: {
    ...Theme.caption(),
    marginBottom: 0,
  },
});
