import PartnerUserApiNetworkRequestConfiguration from '@root/partners.joinroot.com/src/api/partner-user-api-network-request-configuration';

const partnerEventBreakdownsConfiguration = () => {
  return new PartnerUserApiNetworkRequestConfiguration({
    endpoint: 'partner_event_breakdowns',
    method: PartnerUserApiNetworkRequestConfiguration.Methods.GET,
  });
};

export default partnerEventBreakdownsConfiguration;
