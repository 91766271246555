import PartnerUserApiNetworkRequestConfiguration from '@root/partners.joinroot.com/src/api/partner-user-api-network-request-configuration';

const termsConditionsConfiguration = () => {
  return new PartnerUserApiNetworkRequestConfiguration({
    endpoint: 'terms_conditions',
    method: PartnerUserApiNetworkRequestConfiguration.Methods.GET,
    errorCodes: [500],
    successCodes: [200],
  });
};

export default termsConditionsConfiguration;
