import AbstractLayout from '@root/partners.joinroot.com/src/components/abstract-layout';
import CaretLoader from '@root/core/src/components/caret-loader';
import Input, { TextTypes } from '@root/core/src/components/input';
import NextButton from '@root/partners.joinroot.com/src/components/next-button';
import PartnerLoginContainer from '@root/partners.joinroot.com/src/components/partner-login-container';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import environment from '@root/core/src/utils/environment';
import signInConfiguration from '@root/partners.joinroot.com/src/api/sign-in/sign-in-configuration';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useForm from '@root/core/src/hooks/use-form';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { isRequired, isValidEmail } from '@root/core/src/utils/validators';
import { useHistory } from '@root/vendor/react-router';
import { useState } from '@root/vendor/react';

const validations = [
  isRequired('email'),
  isValidEmail('email'),
  isRequired('password'),
];

export default function SignIn({ onLogin }) {
  const [isError, setIsError] = useState(false);
  const [signIn, isSigningIn] = useImperativeNetworkRequest(signInConfiguration);

  const history = useHistory();

  const { trackEvent } = useAnalytics('SIGN_IN');

  const form = useForm({
    validations,
    initialValues: {
      email: '',
      password: '',
    },
  });

  const login = async () => {
    const email = form.getFieldProps('email').value;
    const password = form.getFieldProps('password').value;

    if (email && password) {
      setIsError(false);
      const result = await signIn({
        email,
        password,
      });

      if (result.isSuccess()) {
        trackEvent('SUCCESS');
        onLogin(result.data.accessToken);
      } else {
        trackEvent('ERROR');
        setIsError(true);
      }
    }
  };

  let errorSummary = null;

  if (isError) {
    errorSummary = (
      <div css={styles.errorContainer}>
        <h2 css={styles.errorHeading}>
          {'Invalid email address and/or password'}
        </h2>
        <p css={styles.errorMessage}>{'Please try again.'}</p>
      </div>
    );
  }

  return (
    <AbstractLayout>
      {
        isSigningIn ? (
          <div css={styles.loader}>
            <CaretLoader />
          </div>
        ) : (
          <PartnerLoginContainer subtext={'Sign In'}>
            <form
              css={styles.main}
              onSubmit={form.createSubmitHandler(login)}
            >
              {errorSummary}
              <div css={styles.firstInputContainer}>
                <Input
                  {...form.getFieldProps('email')}
                  autoCapitalize={'off'}
                  id={'email'}
                  inputStyle={styles.input}
                  inputType={TextTypes.EMAIL}
                  label={'Email'}
                />
              </div>
              <Input
                {...form.getFieldProps('password')}
                css={styles.input}
                id={'password'}
                inputType={TextTypes.PASSWORD}
                label={'Password'}
              />
              <div
                css={styles.forgotPassword}
              >
                <div
                  css={styles.forgotPasswordLink}
                  onClick={() => history.push('/forgot-password')}
                >
                  Forgot Password
                </div>
              </div>
              <NextButton disabled={!form.isValid}>
                Sign in
              </NextButton>
              <a
                css={styles.createAccountLink}
                href={`${environment.websiteBaseUrl}/partners/affiliates`}
              >
                Don’t have an account? Apply to become a partner today.
              </a>
            </form>
          </PartnerLoginContainer>
        )
      }
    </AbstractLayout>
  );
}

SignIn.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  loader: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 auto',
    height: 400,
    justifyContent: 'center',
  },
  main: {
    width: '100%',
  },
  firstInputContainer: {
    paddingBottom: 10,
  },
  input: {
    background: Colors.white(),
    border: `1px solid ${Colors.gray40()}`,
  },
  forgotPassword: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  forgotPasswordLink: {
    color: Colors.rootOrange(),
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  createAccountLink: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    color: Colors.rootOrange(),
    fontWeight: 600,
    fontSize: 12,
    marginTop: 20,
  },
  errorContainer: {
    border: '1px solid #E0C4C7',
    backgroundColor: Colors.lightPink(),
    padding: '15px 20px',
    marginBottom: '20px',
    ...Theme.roundedCorners(),
  },
  errorHeading: {
    ...Theme.boldCaptionTitle(),
  },
  errorMessage: {
    ...Theme.caption(),
    marginBottom: 0,
  },
});
