import ErrorReportService from '@root/core/src/services/error-report-service';
import { PricingTypes } from '@root/partners-common/src/models/pricing-rule';
import { RootError } from '@root-common/root-errors';

export class EventTypes {
  static ACCOUNT_KEY = 'account';
  static INSTALL_KEY = 'install';
  static PROFILE_KEY = 'profile';
  static TEST_DRIVE_KEY = 'test_drive';
  static QUOTE_KEY = 'quote';
  static POLICY_KEY = 'policy';
  static AUTO_INITIAL_TERM_KEY = 'auto_initial_term';
  static AUTO_RENEWAL_TERM_KEY = 'auto_renewal_term';
  static RENTERS_INITIAL_TERM_KEY = 'renters_initial_term';
  static RENTERS_RENEWAL_TERM_KEY = 'renters_renewal_term';
  static HOMEOWNERS_COMMISSION_KEY = 'homeowners_commission';

  static MAPPING = {
    [EventTypes.ACCOUNT_KEY]: 'Account',
    [EventTypes.INSTALL_KEY]: 'Install',
    [EventTypes.PROFILE_KEY]: 'Profile',
    [EventTypes.TEST_DRIVE_KEY]: 'Test Drive',
    [EventTypes.QUOTE_KEY]: 'Quote',
    [EventTypes.POLICY_KEY]: 'Policy',
    [EventTypes.AUTO_INITIAL_TERM_KEY]: 'Auto Initial',
    [EventTypes.AUTO_RENEWAL_TERM_KEY]: 'Auto Renewal',
    [EventTypes.RENTERS_INITIAL_TERM_KEY]: 'Renters Initial',
    [EventTypes.RENTERS_RENEWAL_TERM_KEY]: 'Renters Renewal',
    [EventTypes.HOMEOWNERS_COMMISSION_KEY]: 'Homeowners Commission',
  };

  static ACCOUNT = EventTypes.MAPPING[EventTypes.ACCOUNT_KEY];
  static INSTALL = EventTypes.MAPPING[EventTypes.INSTALL_KEY];
  static PROFILE = EventTypes.MAPPING[EventTypes.PROFILE_KEY];
  static TEST_DRIVE = EventTypes.MAPPING[EventTypes.TEST_DRIVE_KEY];
  static QUOTE = EventTypes.MAPPING[EventTypes.QUOTE_KEY];
  static POLICY = EventTypes.MAPPING[EventTypes.POLICY_KEY];

  static PLURAL_MAPPING = Object.freeze({
    [EventTypes.ACCOUNT_KEY]: `${EventTypes.ACCOUNT}s`,
    [EventTypes.INSTALL_KEY]: `${EventTypes.INSTALL}s`,
    [EventTypes.PROFILE_KEY]: `${EventTypes.PROFILE}s`,
    [EventTypes.TEST_DRIVE_KEY]: `${EventTypes.TEST_DRIVE}s`,
    [EventTypes.QUOTE_KEY]: `${EventTypes.QUOTE}s`,
    [EventTypes.POLICY_KEY]: 'Policies',
  });

  static ACCESSOR_MAPPING = Object.freeze({
    [EventTypes.ACCOUNT_KEY]: `${EventTypes.ACCOUNT_KEY}Count`,
    [EventTypes.INSTALL_KEY]: `${EventTypes.INSTALL_KEY}Count`,
    [EventTypes.PROFILE_KEY]: `${EventTypes.PROFILE_KEY}Count`,
    [EventTypes.TEST_DRIVE_KEY]: 'testDriveCount',
    [EventTypes.QUOTE_KEY]: 'quoteViewedCount',
    [EventTypes.POLICY_KEY]: `${EventTypes.POLICY_KEY}Count`,
  });

  static OPTIONS = Object.entries(EventTypes.MAPPING).map(([value, label]) => ({
    value,
    label,
  }));

  static PLURAL_OPTIONS = Object.entries(EventTypes.PLURAL_MAPPING).map(([value, label]) => ({
    value,
    label,
  }));

  static displayValue(partnerPaymentEvent) {
    if (!partnerPaymentEvent) { return '--'; }

    const { eventType } = partnerPaymentEvent;

    const display = EventTypes.MAPPING[eventType];

    if (!display) {
      ErrorReportService.reportError({
        error: new RootError({
          message: `Failed to get display value for unknown payment event type: ${eventType}`,
          name: 'PartnerPaymentEventTypeDisplayError',
          fingerprint: ['Failed to get display value for unknown payment event type'],
        }),
      });
      return '--';
    }

    return display;
  }
}

export class Strategies {
  static FIXED = 'Partners::FixedPaymentEvent';
  static MULTIPLIER = 'Partners::MultiplierPaymentEvent';
  static CARVANA_AUTO = 'Partners::Carvana::AutoPaymentEvent';
  static CARVANA_RENTERS = 'Partners::Carvana::RentersPaymentEvent';
  static CARVANA_HOMEOWNERS = 'Partners::Carvana::HomeownersPaymentEvent';

  static MAPPING = Object.freeze({
    [PricingTypes.FIXED_KEY]: Strategies.FIXED,
    [PricingTypes.MULTIPLIER_KEY]: Strategies.MULTIPLIER,
    [PricingTypes.COMMISSION_KEY]: Strategies.COMMISSION,
  });

  static fromPricingType(pricingType) {
    return Strategies.MAPPING[pricingType];
  }
}

export function costDisplayValue(partnerPaymentEvent) {
  if (!partnerPaymentEvent) { return '--'; }

  const { strategy, options } = partnerPaymentEvent;

  switch (strategy) {
  case Strategies.FIXED:
  case Strategies.MULTIPLIER:
    return `$${options.amount.toFixed(2)}`;
  case Strategies.CARVANA_AUTO:
    return `${options.percentage}%`;
  case Strategies.CARVANA_RENTERS:
    return `${options.percentage}%`;
  case Strategies.CARVANA_HOMEOWNERS:
    return `${options.percentage}%`;
  default:
    ErrorReportService.reportError({
      error: new RootError({
        message: `Failed to get cost display value for unknown payment event strategy: ${strategy}`,
        name: 'PartnerPaymentEventCostError',
        fingerprint: ['Failed to get cost display value for unknown payment event strategy'],
      }),
    });
    return '--';
  }
}
