import PartnerUserApiNetworkRequestConfiguration from '@root/partners.joinroot.com/src/api/partner-user-api-network-request-configuration';

const partnerCampaignPerformanceConfiguration = () => {
  return new PartnerUserApiNetworkRequestConfiguration({
    endpoint: 'partner_daily_events/campaign_data',
    method: PartnerUserApiNetworkRequestConfiguration.Methods.GET,
  });
};

export default partnerCampaignPerformanceConfiguration;
