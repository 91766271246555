import AbstractLayout from '@root/partners.joinroot.com/src/components/abstract-layout';
import Input, { TextTypes } from '@root/core/src/components/input';
import NextButton from '@root/partners.joinroot.com/src/components/next-button';
import PartnerLoginContainer from '@root/partners.joinroot.com/src/components/partner-login-container';
import forgotPasswordConfiguration from '@root/partners.joinroot.com/src/api/password/forgot-password-configuration';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useForm from '@root/core/src/hooks/use-form';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { isRequired, isValidEmail } from '@root/core/src/utils/validators';
import { useHistory } from '@root/vendor/react-router';
import { useState } from '@root/vendor/react';

const validations = [
  isRequired('email'),
  isValidEmail('email'),
];

export default function ForgotPassword() {
  const [isError] = useState(false);
  const [postForgotPassword] = useImperativeNetworkRequest(forgotPasswordConfiguration);
  const history = useHistory();
  const { trackClick } = useAnalytics('FORGOT_PASSWORD');

  const form = useForm({
    validations,
    initialValues: {
      email: '',
    },
  });

  const submit = async () => {
    trackClick('SUBMIT');
    const email = form.getFieldProps('email').value;

    if (email) {
      await postForgotPassword(email);
      history.push('/reset-link-sent');
    }
  };

  let errorSummary = null;

  if (isError) {
    errorSummary = (
      <div css={styles.errorContainer}>
        <h2 css={styles.errorHeading}>
          {'An error occurred'}
        </h2>
        <p css={styles.errorMessage}>{'Please try again.'}</p>
      </div>
    );
  }
  return (
    <AbstractLayout>
      <PartnerLoginContainer subtext={'Forgot password'}>
        <form
          css={styles.main}
          onSubmit={form.createSubmitHandler(submit)}
        >
          <p css={styles.instructions}>Provide the email address associated with your account, and we will send you instructions on how to reset your password.</p>
          {errorSummary}
          <Input
            {...form.getFieldProps('email')}
            css={styles.input}
            id={'email'}
            inputType={TextTypes.EMAIL}
            label={'Email'}
          />
          <NextButton disabled={!form.isValid}>Send request</NextButton>
        </form>
      </PartnerLoginContainer>
    </AbstractLayout>

  );
}

const styles = StyleSheet.create({
  main: {
    width: '100%',
    marginBottom: 30,
  },
  instructions: {
    ...Theme.paragraph2(),
    width: '70%',
    marginBottom: 30,
  },
  input: {
    background: Colors.white(),
    border: `1px solid ${Colors.gray40()}`,
  },
  errorContainer: {
    border: '1px solid #E0C4C7',
    backgroundColor: Colors.lightPink(),
    padding: '15px 20px',
    marginBottom: '20px',
    ...Theme.roundedCorners(),
  },
  errorHeading: {
    ...Theme.boldCaptionTitle(),
  },
  errorMessage: {
    ...Theme.caption(),
    marginBottom: 0,
  },
});
