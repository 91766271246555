import Colors from '@root/partners.joinroot.com/src/utils/colors';
import CountUp from 'react-countup';
import InfoTooltip from '@root/partners.joinroot.com/src/components/info-tooltip';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import dollarSign from '@root/partners.joinroot.com/src/assets/dollar-sign.svg';
import { StyleSheet } from '@root/core/src/utils/styles';
import { keyframes } from '@root/vendor/@emotion/core';

const HeroItem = ({
  footnote,
  header,
  noDataMessage,
  subtitle,
  testID,
  trendChangeComponent,
  tooltipAvailable,
  isDecimalValue,
  isMonetaryValue,
  tooltipContent,
}) => {
  const [animationComplete, setAnimationComplete] = useState(false);

  const headerValuePresent = header !== null && header !== undefined;

  return (
    <div css={styles.heroComponent}>
      <div css={styles.spacingContainer}>
        <div css={styles.titleContainer}>
          <div
            css={styles.mainTitle}
            data-testid={`${testID}-header`}
          >
            {
              !headerValuePresent &&
              <div css={styles.noData}>
                {noDataMessage}
              </div>
            }
            {
              headerValuePresent && isDecimalValue &&
              <div css={styles.countUp}>
                {
                  isMonetaryValue &&
                    <img
                      alt={'$'}
                      css={styles.countUpDollarSign}
                      src={dollarSign}
                    />
                }
                <CountUp
                  decimals={2}
                  duration={1}
                  end={header}
                  onEnd={() => setAnimationComplete(true)}
                  separator={','}
                  start={0.00}
                  useEasing={false}
                />
              </div>
            }
            {
              headerValuePresent && !isDecimalValue &&
              <CountUp
                duration={1}
                end={header}
                onEnd={() => setAnimationComplete(true)}
                start={0}
                useEasing={false}
              />
            }
          </div>
          {
            tooltipAvailable && (
              <div
                css={[
                  styles.tooltipContainer,
                  !animationComplete && styles.hidden,
                  animationComplete && styles.fadeIn,
                ]}
                data-testid={'tooltip'}
              >
                <InfoTooltip
                  content={tooltipContent}
                />
              </div>
            )
          }
          {
            <div
              css={[
                !animationComplete && styles.hidden,
                animationComplete && styles.fadeIn,
              ]}
              data-testid={`${testID}-trend-change`}
            >{trendChangeComponent}
            </div>
          }
        </div>
        <div css={styles.subtitle}>{subtitle}</div>
        <div
          css={styles.footnote}
          data-testid={`${testID}-footnote`}
        >{footnote}
        </div>
      </div>
    </div>
  );
};

HeroItem.propTypes = {
  footnote: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  header: PropTypes.number,
  isDecimalValue: PropTypes.bool,
  isMonetaryValue: PropTypes.bool,
  noDataMessage: PropTypes.string,
  subtitle: PropTypes.string,
  testID: PropTypes.string,
  tooltipAvailable: PropTypes.bool,
  tooltipContent: PropTypes.node,
  trendChangeComponent: PropTypes.node,
};

export default HeroItem;

const fadeIn = keyframes({
  '0%': {
    opacity: '0',
  },
  '100%': {
    opacity: '1',
  },
});

const styles = StyleSheet.create({
  heroComponent: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  spacingContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    ...Responsive.lessThanMd({
      marginLeft: 0,
      marginRight: 0,
    }),
  },
  hidden: {
    opacity: 0,
  },
  fadeIn: {
    animation: `1s ${fadeIn}`,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  mainTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    color: Colors.white(),
    paddingRight: 10,
    ...Responsive.lessThanMd({
      fontSize: 30,
    }),
  },
  subtitle: {
    fontSize: 16,
    textTransform: 'uppercase',
    color: Colors.dashboardLightBlue(),
    marginTop: 5,
    fontWeight: 600,
    ...Responsive.lessThanMd({
      fontSize: 14,
    }),
  },
  footnote: {
    color: Colors.dashboardLightBlue(),
    opacity: '0.5',
    fontSize: 16,
    fontStyle: 'italic',
    marginTop: 10,
    paddingRight: 10,
    ...Responsive.lessThanMd({
      fontSize: 14,
    }),
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 400,
    color: Colors.dashboardLightBlue(),
  },
  countUp: {
    display: 'flex',
  },
  countUpDollarSign: {
    marginRight: 5,
    marginBottom: 10,
  },
});
