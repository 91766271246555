import PartnerUserApiNetworkRequestConfiguration from '@root/partners.joinroot.com/src/api/partner-user-api-network-request-configuration';

export default function signInConfiguration({ email, password }) {
  return new PartnerUserApiNetworkRequestConfiguration({
    endpoint: 'logins/email',
    method: PartnerUserApiNetworkRequestConfiguration.Methods.POST,
    errorCodes: [401],
    bodyParameters: {
      login: {
        email,
        password,
      },
    },
  });
}
