import AuthService from '@root/core/src/services/auth-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useState } from '@root/vendor/react';
import SceneLoader from '@root/core/src/components/scene-loader';
import useValidateToken from '@root/partners.joinroot.com/src/hooks/use-validate-token';

export default function AuthController({
  ProtectedApp,
  PublicRouter,
}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const onLogin = (accessToken) => {
    AuthService.setAccessToken(accessToken);
    setIsAuthenticated(true);
  };

  const onLogout = () => {
    setIsAuthenticated(false);
    AuthService.clear(true);
  };

  const isValidating = useValidateToken(useCallback((result) => {
    const hasValidToken = !!result.data?.valid;
    hasValidToken ? setIsAuthenticated(true) : onLogout();
  }, []));

  if (isValidating) {
    return <SceneLoader hideHeader={true} />;
  }

  if (isAuthenticated) {
    return (
      <ProtectedApp
        onLogout={onLogout}
      />
    );
  }

  return <PublicRouter onLogin={onLogin} />;
}

AuthController.propTypes = {
  ProtectedApp: PropTypes.func.isRequired,
  PublicRouter: PropTypes.func.isRequired,
};
