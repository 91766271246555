import PartnerUserApiNetworkRequestConfiguration from '@root/partners.joinroot.com/src/api/partner-user-api-network-request-configuration';

const forgotPasswordConfiguration = (email) => {
  return new PartnerUserApiNetworkRequestConfiguration({
    endpoint: 'password_resets',
    bodyParameters: {
      password_reset: {
        email,
      },
    },
    method: PartnerUserApiNetworkRequestConfiguration.Methods.POST,
    successCodes: [201],
    errorCodes: [422],
  });
};

export default forgotPasswordConfiguration;
