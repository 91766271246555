import Colors from '@root/partners.joinroot.com/src/utils/colors';
import HeroItem from '@root/partners.joinroot.com/src/components/hero-item';
import Money from '@root/core/src/models/money';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import arrowDown from '@root/partners.joinroot.com/src/assets/arrow-down.svg';
import arrowUp from '@root/partners.joinroot.com/src/assets/arrow-up.svg';
import { EventTypes } from '@root/partners-common/src/models/partner-payment-event';
import { PricingTypes } from '@root/partners-common/src/models/pricing-rule';
import { StyleSheet } from '@root/core/src/utils/styles';

const costPerPaidEvent = (paidEvent, pricingType) => {
  if (!paidEvent) { return ''; }
  const formattedCost = Money.fromDollars(paidEvent.paymentAmount).format();
  const pricing = `${formattedCost} = estimated cost per profile`;
  return pricingType === PricingTypes.MULTIPLIER_KEY ? `${pricing} based on RQI` : pricing;
};

export default function PaidEventHeroItem({
  paidEventMonthlyDifference, paymentEvents = [], pricingType, paidEventCount,
}) {
  const paidEvents = (paymentEvents || []).find((event) => event.eventType === EventTypes.PROFILE_KEY);
  const paidEventsPositive = paidEventMonthlyDifference > 0;

  return (
    <HeroItem
      footnote={costPerPaidEvent(paidEvents, pricingType)}
      header={paidEventCount}
      noDataMessage={'Sorry, no data to display here at the moment'}
      subtitle={'total paid events this month'}
      testID={'paid-event-hero-item'}
      tooltipAvailable={true}
      tooltipContent={
        <span>Click{' '}
          <a
            css={styles.tooltipLink}
            href={'https://sites.google.com/joinroot.com/root-partner-program/payment-structure'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >HERE
          </a>
          {' '}for a map of active states to determine which states are eligible for payment. Earnings shown here are only inclusive of paid events produced from payout-eligible states.
        </span>
      }
      trendChangeComponent={
        paidEventMonthlyDifference ?
          <div
            css={paidEventsPositive ? styles.positiveTrendChange : styles.negativeTrendChange}
            data-testid={'paid-event-trend-change'}
          >
            <img
              css={styles.trendArrow}
              src={paidEventsPositive ? arrowUp : arrowDown}
            />
            <span
              css={styles.trendText}
            >
              {paidEventsPositive && '+'}{paidEventMonthlyDifference} from last month
            </span>
          </div> : null
      }
    />
  );
}

PaidEventHeroItem.propTypes = {
  paidEventCount: PropTypes.number,
  paidEventMonthlyDifference: PropTypes.number,
  paymentEvents: PropTypes.array,
  pricingType: PropTypes.string,
};

const styles = StyleSheet.create({
  positiveTrendChange: {
    borderRadius: 5,
    border: `1px solid ${Colors.highlightGreen()}`,
    padding: 8,
    height: '100%',
    color: Colors.highlightGreen(),
    ...Responsive.lessThanMd({
      fontSize: 14,
    }),
  },
  negativeTrendChange: {
    borderRadius: 5,
    border: `1px solid ${Colors.highlightRed()}`,
    padding: 8,
    height: '100%',
    color: Colors.highlightRed(),
    ...Responsive.lessThanMd({
      fontSize: 14,
    }),
  },
  trendArrow: {
    height: 20,
  },
  trendText: {
    paddingLeft: 6,
  },
  tooltipLink: {
    color: Colors.rootOrange(),
  },
});

