import PropTypes from '@root/vendor/prop-types';

export default function RootLogo({ fillColor }) {
  return (
    <div
      css={{
        paddingRight: 13,
      }}
      data-testid={'root-logo-square'}
    >
      <svg
        fill={'none'}
        height={'35'}
        viewBox={'0 0 35 35'}
        width={'35'}
        xmlns={'http://www.w3.org/2000/svg'}
      >
        <path
          d={'M4.9541 0.374023C2.74496 0.374023 0.954102 2.16489 0.954102 4.37402V30.3584C0.954102 32.5675 2.74496 34.3584 4.9541 34.3584H30.9385C33.1476 34.3584 34.9385 32.5675 34.9385 30.3584V4.37402C34.9385 2.16488 33.1476 0.374023 30.9385 0.374023H4.9541ZM22.2644 19.0124L21.9305 19.1833L26.4139 25.73H21.3018L18.0385 19.9194H16.0719V25.73H11.4607V8.62659C12.6396 8.45835 15.5589 8.05808 17.8715 8.05808C20.6644 8.05808 22.7301 8.63609 24.0115 9.77717C25.0673 10.7168 25.6029 12.0566 25.6029 13.7588C25.6029 16.0084 24.3861 17.9235 22.2644 19.0124ZM16.0719 17.0843H17.7644C19.4895 17.0843 20.7908 15.851 20.7901 14.2153C20.7901 12.3694 19.6858 11.3524 17.6812 11.3524C17.2109 11.3524 16.6354 11.3979 16.2489 11.4651L16.0719 11.4956V17.0843Z'}
          fill={fillColor}
        />
      </svg>
    </div>
  );
}

RootLogo.propTypes = {
  fillColor: PropTypes.string.isRequired,
};
