import DashboardTable from '@root/partners.joinroot.com/src/components/dashboard-table';
import React, { useCallback, useState } from '@root/vendor/react';
import SkeletonLoaderTable from '@root/partners.joinroot.com/src/components/skeleton-loader-table';
import { usePublisherEffectiveness } from '@root/partners.joinroot.com/src/hooks/use-partner-effectiveness';

function PublisherEffectivenessTable() {
  const [publisherEffectivenessData, setPublisherEffectivenessData] = useState([]);
  const publisherEffectivenessColumns = [{
    Header: 'Month (month)',
    accessor: 'month',
  }, {
    Header: 'Site ID (af_siteid)',
    accessor: 'attributedAfSiteId',
  }, {
    Header: 'Traffic Effectiveness (traffic_effectiveness)',
    accessor: 'trafficEffectiveness',
  }];
  const isLoading = usePublisherEffectiveness(useCallback((result) => {
    if (result.data?.publisherEffectiveness) {
      setPublisherEffectivenessData(result.data?.publisherEffectiveness);
    }
  }, []));

  return (
    <div
      data-testid={'publisher-effectiveness-table'}
    >
      {
        isLoading ?
          <SkeletonLoaderTable
            testId={'publisher-effectiveness-table-skeleton'}
            title={'Publisher Effectiveness'}
          /> :
          <DashboardTable
            columns={publisherEffectivenessColumns}
            csvNamePrefix={'root_publisher_effectiveness_table'}
            data={publisherEffectivenessData}
            title={'Publisher Effectiveness'}
          />
      }
    </div>
  );
}

export default React.memo(PublisherEffectivenessTable);
