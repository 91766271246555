import AbstractLayout from '@root/partners.joinroot.com/src/components/abstract-layout';
import PartnerLoginContainer from '@root/partners.joinroot.com/src/components/partner-login-container';
import PartnerPortalButton from '@root/partners.joinroot.com/src/components/partner-portal-button';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useHistory } from '@root/core/src/components/route';
import { useState } from '@root/vendor/react';

export default function ResetLinkSent() {
  const history = useHistory();
  const [isError] = useState(false);
  const { trackClick } = useAnalytics('RESET_LINK_SENT');

  const handleClick = () => {
    trackClick('SIGN_IN');
    history.push('/signin');
  };

  let errorSummary = null;

  if (isError) {
    errorSummary = (
      <div css={styles.errorContainer}>
        <h2 css={styles.errorHeading}>
          {'An error occurred'}
        </h2>
        <p css={styles.errorMessage}>{'Please try again.'}</p>
      </div>
    );
  }
  return (
    <AbstractLayout>
      <PartnerLoginContainer subtext={'Reset link sent'}>
        <div
          css={styles.main}
        >
          <p css={styles.instructions}>If we have an account under that address, we will send instructions on how to reset your password.</p>
          {errorSummary}
          <PartnerPortalButton onClick={handleClick}>
            Sign in
          </PartnerPortalButton>
        </div>
      </PartnerLoginContainer>
    </AbstractLayout>
  );
}

const styles = StyleSheet.create({
  main: {
    width: '100%',
    marginBottom: 30,
  },
  instructions: {
    ...Theme.paragraph2(),
    width: '70%',
    marginBottom: 30,
  },
  errorContainer: {
    border: '1px solid #E0C4C7',
    backgroundColor: Colors.lightPink(),
    padding: '15px 20px',
    marginBottom: '20px',
    ...Theme.roundedCorners(),
  },
  errorHeading: {
    ...Theme.boldCaptionTitle(),
  },
  errorMessage: {
    ...Theme.caption(),
    marginBottom: 0,
  },
});
