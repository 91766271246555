import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import RootLogo from '@root/partners.joinroot.com/src/components/root-logo.js';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useResize from '@root/core/src/hooks/use-resize';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { bold } from '@root/brand/src/assets/fonts/root-sans/emotion';
import { useHistory } from '@root/core/src/components/route';
import { useState } from '@root/vendor/react';

const ResponsiveHeaderLogo = ({ logoColor, textStyling }) => {
  const checkSmallScreen = () => { return window.innerWidth < Responsive.BREAKPOINTS.md; };
  const [isSmallScreen, setIsSmallScreen] = useState(checkSmallScreen);
  const { trackClick } = useAnalytics('RESPONSIVE_HEADER', false);
  const history = useHistory();

  useResize({
    onResize: () => {
      setIsSmallScreen(checkSmallScreen);
    },
  });

  const handleClickHeaderLogo = () => {
    trackClick('HEADER_ICON');
    history.push('/');
  };

  return (
    <div
      css={styles.logoName}
      onClick={handleClickHeaderLogo}
    >
      <RootLogo fillColor={logoColor || Colors.rootOrange()} />
      {!isSmallScreen && (
        <div>
          <span css={styles.rootText}>Root</span>
          <span css={[styles.partnerProgramText, textStyling]}>Partner Program</span>
        </div>
      )}
    </div>
  );
};

ResponsiveHeaderLogo.propTypes = {
  logoColor: PropTypes.string,
  onClick: PropTypes.func,
  textStyling: PropTypes.object,
};

export default ResponsiveHeaderLogo;

const styles = StyleSheet.create({
  logoName: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    zIndex: 100,
    paddingLeft: 10,
  },
  rootText: {
    ...bold(),
    fontSize: 24,
    marginRight: 5,
  },
});
