import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

function NavMenuButton({ buttonBarStyles, toggleNavState }) {
  return (
    <button
      css={styles.button}
      data-testid={'menu-button'}
      onClick={toggleNavState}
    >
      <div
        className={'button-bar'}
        css={[styles.buttonBar, buttonBarStyles]}
      />
      <div
        className={'button-bar'}
        css={[styles.buttonBar, buttonBarStyles]}
      />
      <div
        className={'button-bar'}
        css={[styles.buttonBar, buttonBarStyles]}
      />
    </button>
  );
}

const styles = StyleSheet.create({
  button: {
    display: 'inline-block',
    backgroundColor: 'transparent',
    padding: '0',
    border: 'none',
    outline: 'none',
    '.nav-is-open &': {
      '& .button-bar:nth-of-type(1)': {
        transform: 'translateY(8px) rotate(225deg)',
      },
      '& .button-bar:nth-of-type(2)': {
        opacity: 0,
      },
      '& .button-bar:nth-of-type(3)': {
        transform: 'translateY(-8px) rotate(-45deg)',
      },
    },
    ...Responsive.md({
      display: 'none',
    }),
  },
  buttonBar: {
    width: 25,
    height: 2,
    margin: '6px 0',
    backgroundColor: Colors.white(),
    transition: 'all 400ms cubic-bezier(.37, 0, .28, 1)',
  },
});

NavMenuButton.propTypes = {
  buttonBarStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  toggleNavState: PropTypes.func.isRequired,
};

export default NavMenuButton;
