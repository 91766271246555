import DashboardTable from '@root/partners.joinroot.com/src/components/dashboard-table';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';

const EventBreakdownTable = ({ eventBreakdownData, isDiscrepancyData = false }) => {
  const columns = useMemo(() => {
    return [{
      Header: 'Profile Timestamp',
      accessor: 'profileCreatedTimestamp',
    }, {
      Header: 'Media Source (pid)',
      accessor: 'attributedMediaSource',
    }, {
      Header: 'AppsFlyer ID',
      accessor: 'appsflyerId',
    }, {
      Header: 'Web Profile',
      accessor: 'signupPlatform',
    }, {
      Header: 'Site ID (af_siteid)',
      accessor: 'attributedAfSiteId',
    }, {
      Header: 'Sub Site ID (af_sub_siteid)',
      accessor: 'attributedAfSubsiteId',
    }, {
      Header: 'Sub 1 (af_sub1)',
      accessor: 'attributedAfSub1',
    }, {
      Header: 'Sub 2  (af_sub2)',
      accessor: 'attributedAfSub2',
    }, {
      Header: 'Sub 3  (af_sub3)',
      accessor: 'attributedAfSub3',
    }, {
      Header: 'Sub 4 (af_sub4)',
      accessor: 'attributedAfSub4',
    }, {
      Header: 'Sub 5 (af_sub5)',
      accessor: 'attributedAfSub5',
    }, {
      Header: 'State',
      accessor: 'attributedState',
    }, {
      Header: 'Platform',
      accessor: 'initialMobilePlatform',
    }, {
      Header: 'Campaign (c)',
      accessor: 'attributedCampaign',
    }, {
      Header: 'Ad (af_ad)',
      accessor: 'attributedAd',
    }, {
      Header: 'Adset (af_adset)',
      accessor: 'attributedAdset',
    }, {
      Header: 'Effectiveness*',
      accessor: 'rootQualityIndicator',
    }];
  }, []);

  const csvNamePrefix = isDiscrepancyData ? 'root_discrepancy_breakdown_table_export' : 'root_detailed_event_breakdown_table_export';
  const title = isDiscrepancyData ? 'Discrepancy Breakdown' : 'Detailed Event Breakdown';

  return (
    <DashboardTable
      columns={columns}
      csvNamePrefix={csvNamePrefix}
      data={eventBreakdownData}
      styleOverrides={styles}
      title={title}
    />
  );
};

EventBreakdownTable.propTypes = {
  eventBreakdownData: PropTypes.arrayOf(PropTypes.shape({
    profileCreatedTimestamp: PropTypes.string.isRequired,
    attributedMediaSource: PropTypes.string.isRequired,
    appsflyerId: PropTypes.string.isRequired,
    signupPlatform: PropTypes.string.isRequired,
    attributedAfSiteId: PropTypes.string.isRequired,
    attributedAfSubsiteId: PropTypes.string.isRequired,
    attributedAfSub1: PropTypes.string.isRequired,
    attributedAfSub2: PropTypes.string.isRequired,
    attributedAfSub3: PropTypes.string.isRequired,
    attributedAfSub4: PropTypes.string.isRequired,
    attributedAfSub5: PropTypes.string.isRequired,
    attributedState: PropTypes.string.isRequired,
    initialMobilePlatform: PropTypes.string.isRequired,
    attributedCampaign: PropTypes.string.isRequired,
    attributedAd: PropTypes.string.isRequired,
    attributedAdset: PropTypes.string.isRequired,
    rootQualityIndicator: PropTypes.number.isRequired,
  })),
  isDiscrepancyData: PropTypes.bool,
};

export default EventBreakdownTable;

const styles = StyleSheet.create({
  header: {
    borderRight: '18px solid transparent',
    width: 'max-content',
    ...Responsive.ultra({
      width: '100%',
    }),
  },
  tableContentsContainer: {
    overflow: 'auto',
  },
  tableRows: {
    width: 'max-content',
    ...Responsive.ultra({
      width: '100%',
    }),
  },
});
