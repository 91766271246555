import PartnerUserApiNetworkRequestConfiguration from '@root/partners.joinroot.com/src/api/partner-user-api-network-request-configuration';

const partnerAttributedEarningsConfiguration = () => {
  return new PartnerUserApiNetworkRequestConfiguration({
    endpoint: 'partner_attributed_earnings',
    method: PartnerUserApiNetworkRequestConfiguration.Methods.GET,
  });
};

export default partnerAttributedEarningsConfiguration;
