import dayjs from '@root/vendor/dayjs';
import { useEffect, useState } from '@root/vendor/react';

const createDataForBarChart = (dailyEvents, lookbackNumberOfDays = 90) => {
  const campaignsByDate = dailyEvents.reduce(groupByDate, {});

  const dayArray = [...Array(lookbackNumberOfDays)].map((_, i) => dayjs().subtract(i, 'days').format('YYYY-MM-DD'));

  return dayArray.map((date) => {
    const campaignData = campaignsByDate[date];
    if (campaignData) {
      return {
        date,
        ...campaignData,
      };
    }
    return {
      date,
    };
  }).sort(sortByDate);
};

const groupByDate = (transformedData, dailyEvent) => {
  const {
    eventDate, attributedCampaign, profileCount,
  } = dailyEvent;

  if (!Object.prototype.hasOwnProperty.call(transformedData, eventDate)) {
    transformedData[eventDate] = {};
  }
  transformedData[eventDate][attributedCampaign] = profileCount;

  return transformedData;
};

const sortByDate = (a, b) => a.date > b.date ? 1 : -1;

const legendValues = (dailyEvents) => [...new Set(dailyEvents.map((event) => event.attributedCampaign))];

const initialDataForAnimation = (graphData) => graphData.map((entry) => ({
  date: entry.date,
}));

const LOADING_CAMPAIGN_PLACEHOLDER = 'Loading...',
  DEFAULT_DATA_LENGTH = 90,
  MIN_HEIGHT = 20,
  WAVE_INTENSITY = 10,
  WAVE_WIDTH_MODIFIER = 5,
  WAVE_SPEED = 2,
  REFRESH_DELAY_MS = 300;

const useLoadingData = ({
  data, isLoading, length = DEFAULT_DATA_LENGTH,
}) => {
  const [loadingData] = useState(data);
  const [shiftValue, setShiftValue] = useState(1);

  useEffect(() => {
    if (!isLoading) { return; }
    const shiftingCounts = setInterval(() => {
      for (let i = 0; i < length; i++) {
        loadingData[i][LOADING_CAMPAIGN_PLACEHOLDER] = calculateWaveValue(i, shiftValue);
      }
      setShiftValue(shiftValue + WAVE_SPEED);
    }, REFRESH_DELAY_MS);

    return () => clearInterval(shiftingCounts);
  }, [isLoading, length, loadingData, shiftValue]);

  return isLoading ? loadingData : data;
};

const calculateWaveValue = (i, shift = 0) => {
  return WAVE_INTENSITY * Math.sin((i + shift) / WAVE_WIDTH_MODIFIER) + MIN_HEIGHT;
};

const makeLoadingDataWave = (length = DEFAULT_DATA_LENGTH) => {
  const loadingData = [];
  for (let i = 0; i < length; i++) {
    loadingData.push({
      attributedCampaign: LOADING_CAMPAIGN_PLACEHOLDER,
      profileCount: calculateWaveValue(i),
      eventDate: dayjs().subtract(length - i, 'days').format('YYYY-MM-DD'),
    });
  }
  return loadingData;
};

export {
  DEFAULT_DATA_LENGTH,
  legendValues,
  createDataForBarChart,
  initialDataForAnimation,
  makeLoadingDataWave,
  useLoadingData,
};

