import publisherEffectivenessConfiguration from '@root/partners.joinroot.com/src/api/partner-effectiveness/publisher-effectiveness-configuration';
import subsiteEffectivenessConfiguration from '@root/partners.joinroot.com/src/api/partner-effectiveness/subsite-effectiveness-configuration';
import useSafeNetworkRequest from '@root/core/src/hooks/use-safe-network-request';

const publisherConfiguration = publisherEffectivenessConfiguration();
const subsiteConfiguration = subsiteEffectivenessConfiguration();

function usePublisherEffectiveness(callback) {
  return useSafeNetworkRequest(publisherConfiguration, callback);
}

function useSubsiteEffectiveness(callback) {
  return useSafeNetworkRequest(subsiteConfiguration, callback);
}

export {
  usePublisherEffectiveness,
  useSubsiteEffectiveness,
};
