import PartnerUserApiNetworkRequestConfiguration from '@root/partners.joinroot.com/src/api/partner-user-api-network-request-configuration';

const publisherEffectivenessConfiguration = () => {
  return new PartnerUserApiNetworkRequestConfiguration({
    endpoint: 'publisher_effectiveness',
    method: PartnerUserApiNetworkRequestConfiguration.Methods.GET,
  });
};

export default publisherEffectivenessConfiguration;
