import Button, { BUTTON_SIZE_SMALL } from '@root/core/src/components/button';
import propTypes from '@root/vendor/prop-types';
import { BUTTON_ICON_POSITION_LEFT, BUTTON_ICON_POSITION_RIGHT } from '@root/core/src/components/button';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

const PartnerPortalButton = ({
  onClick, disabled = false, icon, iconPosition, children,
}) => (
  <Button
    css={styles.button}
    disabled={disabled}
    iconComponent={icon}
    iconPosition={iconPosition}
    onClick={onClick}
    size={BUTTON_SIZE_SMALL}
  >
    {children}
  </Button>
);

PartnerPortalButton.propTypes = {
  children: propTypes.node.isRequired,
  disabled: propTypes.bool,
  icon: propTypes.oneOfType([
    propTypes.elementType,
    propTypes.bool,
  ]),
  iconPosition: propTypes.oneOf([BUTTON_ICON_POSITION_LEFT, BUTTON_ICON_POSITION_RIGHT]),
  onClick: propTypes.func,
};

export default PartnerPortalButton;

const styles = StyleSheet.create({
  button: {
    width: '100%',
    borderRadius: 2,
    fontSize: 14,
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 20,
    marginBottom: 20,
    ':disabled': {
      backgroundColor: Colors.gray20(),
      color: Colors.gray40(),
    },
  },
});
