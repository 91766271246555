import PartnerUserApiNetworkRequestConfiguration from '@root/partners.joinroot.com/src/api/partner-user-api-network-request-configuration';

export default function resetPasswordConfiguration(passwordReset) {
  return new PartnerUserApiNetworkRequestConfiguration({
    endpoint: 'password_resets',
    bodyParameters: {
      passwordReset,
    },
    method: PartnerUserApiNetworkRequestConfiguration.Methods.PUT,
    errorCodes: [422],
    successCodes: [200],
  });
}
