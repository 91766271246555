import Colors from '@root/partners.joinroot.com/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import openNewWindowIcon from '@root/partners.joinroot.com/src/assets/open-new-window.svg';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

const DocumentationLink = ({
  href, onClick, text,
}) => {
  return (
    <div css={styles.container}>
      <a
        css={styles.link}
        href={href}
        onClick={onClick}
        rel={'noreferrer'}
        target={'_blank'}
      >
        {text}
        <img
          css={styles.icon}
          data-testid={'openNewWindowIcon'}
          src={openNewWindowIcon}
        />
      </a>
    </div>
  );
};

DocumentationLink.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default DocumentationLink;

const styles = StyleSheet.create({
  link: {
    fontSize: 16,
    marginBottom: 5,
    cursor: 'pointer',
    ...Theme.paragraph1(),
    color: Colors.rootOrange(),
    textDecoration: 'underline',
  },
  icon: {
    marginBottom: 5,
    marginLeft: 20,
  },
});
