import PartnerUserApiNetworkRequestConfiguration from '@root/partners.joinroot.com/src/api/partner-user-api-network-request-configuration';

export default function tokenSignupConfiguration(signupParams) {
  return new PartnerUserApiNetworkRequestConfiguration({
    endpoint: 'sign_up',
    method: PartnerUserApiNetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      sign_up: signupParams,
    },
    errorCodes: [422],
    successCodes: [201],
  });
}
