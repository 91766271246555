import PropTypes from '@root/vendor/prop-types';
import Shadows from '@root/brand/src/utils/shadows';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { IconChevron } from '@root/core/src/components/icons/chevron';

export default function TitledDropdown({
  buttonStyle,
  chevronStyle,
  closedButtonStyle,
  closedChevronColor = Colors.white(),
  contentStyle,
  contents,
  isOpen,
  onClick,
  openButtonStyle,
  openChevronColor = Colors.gray40(),
  openStyle,
  title,
}) {
  const handleClick = (e, name) => {
    onClick(e, name);
  };

  return (
    <div>
      <button
        css={[
          styles.button,
          isOpen ? [styles.openSelection, openButtonStyle] : [styles.closedAssignButton, closedButtonStyle],
          buttonStyle,
        ]}
      >
        {title}
        <div>
          <IconChevron
            color={isOpen ? openChevronColor : closedChevronColor}
            direction={'down'}
            iconStyles={chevronStyle}
          />
        </div>
      </button>
      <div
        css={[{
          boxShadow: isOpen ? Shadows.hoverShadow().boxShadow : null,
        }, styles.contentBase, styles.openStyle, openStyle]}
      >
        <div>
          {contents.map((c, i) => {
            return (
              <div
                css={[styles.contentCellBase,
                  isOpen && contentStyle,
                  isOpen && styles.contentCellOpen,
                ]}
                key={i}
                onClick={() => handleClick(c.value, c.label)}
              >
                {isOpen && c.icon}
                {isOpen && c.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

TitledDropdown.propTypes = {
  buttonStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  chevronStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  closedButtonStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  closedChevronColor: PropTypes.string,
  contents: PropTypes.array.isRequired,
  contentStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  openButtonStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  openChevronColor: PropTypes.string,
  openStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    fontSize: 14,
    width: 175,
    cursor: 'pointer',
    padding: 7,
    ':focus': {
      outline: 'none',
    },
  },
  contentBase: {
    width: 175,
    zIndex: 100,
    borderRadius: 2,
    cursor: 'pointer',
    position: 'absolute',
  },
  contentCellBase: {
    zIndex: 200,
    transition: 'all 150ms ease-in-out',
    paddingLeft: 12,
    fontSize: 14,
    backgroundColor: Colors.white(),
    borderTop: `1px solid ${Colors.gray30()}`,
    alignItems: 'center',
    opacity: 0,
    borderRadius: 2,
    height: 0,
    display: 'flex',
    margin: '0 auto',
    ':hover': {
      color: Colors.white(),
      backgroundColor: Colors.rootOrange(),
    },
  },
  contentCellOpen: {
    opacity: 1,
    height: 40,
  },
  closedAssignButton: {
    color: Colors.white(),
    border: `1px solid ${Colors.rootOrange()}`,
    backgroundColor: Colors.rootOrange(),
  },
  openSelection: {
    color: Colors.gray50(),
    border: `1px solid ${Colors.white()}`,
    backgroundColor: Colors.white(),
  },
});
