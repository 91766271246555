import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors } from '@root/core/src/utils/styles';

export function IconLock({ color = Colors.black(), iconStyles }) {
  return (
    <svg
      css={iconStyles}
      fill={'none'}
      height={'19'}
      viewBox={'0 0 16 19'}
      width={'16'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={'M5.18538 3.98625C5.18538 2.53418 6.36252 1.35704 7.81459 1.35704C9.26667 1.35704 10.4438 2.53418 10.4438 3.98625V4.79524H11.6573V3.98625C11.6573 1.86399 9.93685 0.143555 7.81459 0.143555C5.69233 0.143555 3.97189 1.86399 3.97189 3.98625V4.79524H5.18538V3.98625Z'}
        fill={color}
      />
      <path
        clipRule={'evenodd'}
        d={'M0.533691 6.74417C0.533691 6.33799 0.862961 6.00872 1.26914 6.00872H14.36C14.7662 6.00872 15.0955 6.33799 15.0955 6.74417V10.8627C15.0955 14.8838 11.8357 18.1436 7.81459 18.1436C3.79346 18.1436 0.533691 14.8838 0.533691 10.8627V6.74417ZM8.51274 11.5137C9.05682 11.253 9.43257 10.6972 9.43257 10.0537C9.43257 9.16008 8.70817 8.43569 7.81459 8.43569C6.92101 8.43569 6.19661 9.16008 6.19661 10.0537C6.19661 10.6972 6.57236 11.253 7.11643 11.5137L6.19661 14.5031H9.43256L8.51274 11.5137Z'}
        fill={color}
        fillRule={'evenodd'}
      />
    </svg>
  );
}

IconLock.propTypes = {
  color: PropTypes.string,
  iconStyles: PropTypes.object,
};
