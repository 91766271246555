import Colors from '@root/partners.joinroot.com/src/utils/colors';
import HeroItem from '@root/partners.joinroot.com/src/components/hero-item';
import Money from '@root/core/src/models/money';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { EventTypes } from '@root/partners-common/src/models/partner-payment-event';
import { PricingTypes } from '@root/partners-common/src/models/pricing-rule';
import { StyleSheet } from '@root/core/src/utils/styles';

function EstimatedEarnings({ paymentEvents, pricingType }) {
  const earningsList = (paymentEvents || []).map(({ paymentAmount, eventType }) => {
    const formattedAmount = Money.fromDollars(paymentAmount).format();
    let earning = `${EventTypes.PLURAL_MAPPING[eventType]} x ${formattedAmount}`;
    earning = pricingType === PricingTypes.MULTIPLIER_KEY ? `${earning} x RQI` : earning;
    return paymentEvents.length > 1 ? `(${earning})` : earning;
  });

  return (
    <>
      *Estimated earnings = {
        earningsList.length && earningsList
          .map((earnings, index) => (
            <span
              css={styles.earnings}
              key={index}
            >{earnings}
            </span>)
          ).reduce((prev, curr) => [prev, ' + ', curr])
      }
    </>
  );
}

EstimatedEarnings.propTypes = {
  paymentEvents: PropTypes.array,
  pricingType: PropTypes.string,
};

function EarningsHeroItem({
  paymentEvents, potentialEarnings, pricingType,
}) {
  return (
    <HeroItem
      footnote={potentialEarnings !== null && potentialEarnings !== undefined &&
        <EstimatedEarnings
          paymentEvents={paymentEvents}
          pricingType={pricingType}
        />
      }
      header={potentialEarnings}
      isDecimalValue={true}
      isMonetaryValue={true}
      noDataMessage={'Sorry, no data to display here at the moment'}
      subtitle={'*potential earnings this month'}
      testID={'earnings-hero-item'}
      tooltipAvailable={true}
      tooltipContent={
        <span>For more details regarding how your earnings are calculated, please see the Root Partner Platform Payment Structure site{' '}
          <a
            css={styles.tooltipLink}
            href={'https://sites.google.com/joinroot.com/root-partner-program/payment-structure/state-availability'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >HERE
          </a>.
        </span>
      }
    />
  );
}

EarningsHeroItem.propTypes = {
  paymentEvents: PropTypes.array,
  potentialEarnings: PropTypes.number,
  pricingType: PropTypes.string,
};

const styles = StyleSheet.create({
  earnings: {
    whiteSpace: 'nowrap',
  },
  tooltipLink: {
    color: Colors.rootOrange(),
  },
});

export default React.memo(EarningsHeroItem);
