import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useRef, useState } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

import checkmark from '@root/partners.joinroot.com/src/assets/checkmark-orange.svg';
import copyDocument from '@root/partners.joinroot.com/src/assets/copy-document.svg';

export default function CopyableLink({ link }) {
  const { trackClick } = useAnalytics('COPYABLE_LINK', false);
  const [copied, setCopied] = useState(false);
  const mounted = useRef(true);

  useEffect(() => () => {
    mounted.current = false;
  }, [mounted]);

  const copyLink = () => {
    trackClick('LINK');
    navigator.clipboard.writeText(link);

    setCopied(true);
    setTimeout(() => {
      if (mounted.current) {
        setCopied(false);
      }
    }, 1500);
  };

  return (
    <div
      css={styles.copyableLink}
      onClick={copyLink}
    >
      <div css={styles.link}>{link}</div>
      <div css={styles.icon}>
        <img
          alt={copied ? 'checkmark-icon' : 'copy-link-icon'}
          src={copied ? checkmark : copyDocument}
        />
      </div>
    </div>
  );
}

CopyableLink.propTypes = {
  link: PropTypes.string,
};

const styles = StyleSheet.create({
  copyableLink: {
    backgroundColor: Colors.white(),
    height: 54,
    borderRadius: 4,
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    border: `1px solid ${Colors.gray30()}`,
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  link: {
    marginRight: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    backgroundColor: Colors.white(),
    height: '100%',
    width: 20,
    display: 'flex',
    justifyContent: 'center',
    marginRight: 20,
  },
});
