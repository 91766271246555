import termsConditionsConfiguration from '@root/partners.joinroot.com/src/api/terms-conditions/terms-conditions-configuration';
import useNetworkRequest from '@root/core/src/hooks/use-network-request';
import { useCallback, useState } from '@root/vendor/react';

const configuration = termsConditionsConfiguration();

const useTermsConditions = () => {
  const [termsConditionsUrl, setTermsConditionsUrl] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useNetworkRequest(configuration, useCallback((result) => {
    if (result.isSuccess()) {
      setTermsConditionsUrl(result.data?.customTermsUrl || result.data?.standardTermsUrl);
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  }, []));

  return {
    isLoading,
    termsConditionsUrl,
    isError,
  };
};

export default useTermsConditions;
