import Colors from '@root/partners.joinroot.com/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import SkeletonLoaderBar from '@root/partners.joinroot.com/src/components/skeleton-loader-bar';
import Table from '@root/internal-dashboards-core/src/components/table';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

const SkeletonLoaderTable = ({ title, testId }) => {
  const headerLoader = () => <div css={styles.headerLoader}><SkeletonLoaderBar /></div>;
  const cellLoader = () => <div css={styles.cellLoader}><SkeletonLoaderBar /></div>;
  const emptyData = [...Array(6)].map(() => ({}));

  const placeholderColumns = [{
    Header: headerLoader,
    accessor: 'first-column',
    Cell: cellLoader,
    width: 50,
  }, {
    Header: headerLoader,
    accessor: 'second-column',
    Cell: cellLoader,
    width: 50,
  }, {
    Header: headerLoader,
    accessor: 'third-column',
    Cell: cellLoader,
    width: 50,
  }];

  return (
    <div data-testid={testId}>
      <Table
        columns={placeholderColumns}
        data={emptyData}
        styleOverrides={styles}
        title={title}
      />
    </div>
  );
};

SkeletonLoaderTable.propTypes = {
  testId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SkeletonLoaderTable;

const loaderStyling = {
  height: 40,
  display: 'flex',
  alignItems: 'center',
};

const styles = StyleSheet.create({
  headerLoader: {
    ...loaderStyling,
    width: 140,
    ...Responsive.lessThanSm({
      width: 100,
    }),
  },
  cellLoader: {
    ...loaderStyling,
    width: 100,
    ...Responsive.lessThanSm({
      width: 70,
    }),
  },
  headerItemContainer: {
    ...Responsive.lessThanSm({
      paddingLeft: 5,
    }),
  },
  headerItem: {
    border: 'none',
  },
  titleContent: {
    ...Theme.paragraph2({
      bold: true,
    }),
    textTransform: 'uppercase',
    color: Colors.black(),
  },
  header: {
    width: 'calc(100% - 20px)',
  },
});
