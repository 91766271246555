import Colors from '@root/partners.joinroot.com/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import infoIcon from '@root/partners.joinroot.com/src/assets/info.svg';
import { StyleSheet } from '@root/core/src/utils/styles';
import { keyframes } from '@root/vendor/@emotion/core';
import { useEffect, useRef, useState } from '@root/vendor/react';

const InfoTooltip = ({ content }) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const mouseOver = useRef(false);
  const mounted = useRef(true);

  useEffect(() => () => {
    mounted.current = false;
  }, [mounted]);

  const startCloseTooltip = () => {
    mouseOver.current = false;

    setTimeout(() => {
      if (mounted.current && !mouseOver.current) {
        setDisplayTooltip(false);
      }
    }, 300);
  };

  const openTooltip = () => {
    mouseOver.current = true;
    setDisplayTooltip(true);
  };

  return (
    <div
      css={styles.tooltipContainer}
      onMouseEnter={openTooltip}
      onMouseLeave={startCloseTooltip}
    >
      <img
        css={styles.tooltipIcon}
        data-testid={'infoIcon'}
        src={infoIcon}
      />
      {displayTooltip &&
        <div
          css={styles.tooltipText}
          data-testid={'tooltipText'}
        >
          {content}
        </div>
      }
    </div>
  );
};

InfoTooltip.propTypes = {
  content: PropTypes.node.isRequired,
};

export default InfoTooltip;

const slideUp = keyframes({
  '0%': {
    opacity: 0,
    top: '150%',
  },
  '70%': {
    opacity: 0,
    top: '150%',
  },
  '100%': {
    opacity: 1,
    top: '138%',
  },
});

const slideLeft = keyframes({
  '0%': {
    opacity: 0,
    left: '185%',
  },
  '70%': {
    opacity: 0,
    left: '185%',
  },
  '100%': {
    opacity: 1,
    left: '155%',
  },
});

const styles = StyleSheet.create({
  tooltipContainer: {
    position: 'relative',
    display: 'inline-block',
    marginBottom: 10,
    marginRight: 11,
  },
  tooltipText: {
    display: 'inline',
    fontWeight: 100,
    fontSize: 16,
    lineHeight: 1.4,
    width: 190,
    backgroundColor: Colors.tooltipBackground(),
    color: Colors.white(),
    borderRadius: 2,
    padding: '10px 15px',
    position: 'absolute',
    zIndex: 1,
    ...Responsive.md({
      top: -4,
      animation: `0.7s ${slideLeft}`,
      left: '155%',
      '&::after': {
        content: '\'\'',
        position: 'absolute',
        top: 15,
        right: '100%',
        marginTop: -5,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: 'transparent ' + Colors.tooltipBackground() + ' transparent transparent',
      },
    }),
    ...Responsive.lessThanMd({
      left: '40%',
      marginLeft: -77,
      animation: `0.7s ${slideUp}`,
      top: '138%',
      '&::after': {
        content: '\'\'',
        position: 'absolute',
        bottom: '100%',
        left: '40%',
        marginLeft: -5,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: 'transparent transparent ' + Colors.tooltipBackground() + ' transparent',
      },
    }),
  },
  tooltipIcon: {
    height: 16,
  },
});
