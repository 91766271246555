import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Table from '@root/internal-dashboards-core/src/components/table';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

const PaginatedTable = ({
  columns, data, csvExportable, csvNamePrefix, pageSize = 10, styleOverrides, title, noDataMessage = 'Sorry, no data to display here at the moment',
}) => {
  return (
    <>
      <Table
        columns={columns}
        csvExportable={csvExportable}
        csvNamePrefix={csvNamePrefix}
        data={data}
        initialState={{
          pageSize,
          pageIndex: 0,
        }}
        isPaginated={true}
        paginationPageSize={pageSize}
        styleOverrides={styleOverrides}
        title={title}
        useVirtualRows={false}
      />
      {data.length === 0 &&
      <div css={styles.noData}>
        {noDataMessage}
      </div>
      }
    </>
  );
};

PaginatedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      sortType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
    })
  ).isRequired,
  csvExportable: PropTypes.bool,
  csvNamePrefix: PropTypes.string,
  data: PropTypes.array.isRequired,
  headerStyles: PropTypes.object,
  noDataMessage: PropTypes.string,
  pageSize: PropTypes.number,
  rowStyles: PropTypes.object,
  styleOverrides: PropTypes.object,
  title: PropTypes.string,
  titleStyles: PropTypes.object,
};

export default PaginatedTable;

const styles = StyleSheet.create({
  noData: {
    display: 'flex',
    justifyContent: 'center',
    padding: '55px 0',
    ...Theme.paragraph2(),
  },
});
