import DashboardTable from '@root/partners.joinroot.com/src/components/dashboard-table';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo } from '@root/vendor/react';

const CampaignPerformanceTable = ({ campaignAdData }) => {
  const columns = useMemo(() => {
    return [{
      Header: 'Campaign',
      accessor: 'attributedCampaign',
    }, {
      Header: 'Ad',
      accessor: 'attributedAd',
    }, {
      Header: 'Profiles',
      accessor: 'profileCount',
    }];
  }, []);

  const data = useMemo(() => {
    return campaignAdData;
  }, [campaignAdData]);

  return (
    <DashboardTable
      columns={columns}
      csvNamePrefix={'root_campaign_performance_table_export'}
      data={data}
      title={'Campaign Performance'}
    />
  );
};

CampaignPerformanceTable.propTypes = {
  campaignAdData: PropTypes.arrayOf(PropTypes.shape({
    attributedCampaign: PropTypes.string.isRequired,
    attributedAd: PropTypes.string.isRequired,
    profileCount: PropTypes.number.isRequired,
  })),
};

export default React.memo(CampaignPerformanceTable);
