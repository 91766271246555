import { RootError } from '@root-common/root-errors';

const xAxisValues = (data, xAxisOffset) => data.map((x) => x.date).filter((_, index) => index % xAxisOffset === 0);

const maxYAxisValue = (data) => {
  const yAxisValuesFromData = data.map(
    (campaignInfo) => Object.values(campaignInfo).filter((field) => !isNaN(field))
  ).map((profileCount) => profileCount.reduce((a, b) => a + b, 0));

  return yAxisValuesFromData.length === 0 ? 0 : Math.max(...yAxisValuesFromData);
};

const yAxisValues = (offset, gridBoundary) => {
  if (gridBoundary === 0) {
    return [0];
  } else if (offset === gridBoundary) {
    return [0, gridBoundary];
  } else if (offset > gridBoundary) {
    return [0];
  }

  return [...Array(gridBoundary + 1)]
    .map((_, index) => index)
    .filter((x) => x % Math.ceil(offset) === 0);
};

const LARGE_SET_Y_DIVISIBLES = [25];
const Y_DIVISIBLES = [25, 10];
const CLEAN_OFFSET_VALUE = 5;
const SMALL_CHART_THRESHOLD = 60;

const closestHigherDivisible = (value) => {
  const divisors = value > SMALL_CHART_THRESHOLD ? LARGE_SET_Y_DIVISIBLES : Y_DIVISIBLES;
  let updatedValue = Math.ceil(value);
  while (!divisors.some((d) => { return updatedValue % d === 0; })) {
    updatedValue += 1;
  }
  return updatedValue;
};

const determineYAxisOffset = (maxYDisplayValue) => {
  if (maxYDisplayValue <= 10) {
    return 2;
  }
  if (LARGE_SET_Y_DIVISIBLES.concat(Y_DIVISIBLES).some((d) => { return d % CLEAN_OFFSET_VALUE !== 0; }) ||
      maxYDisplayValue % CLEAN_OFFSET_VALUE !== 0) {
    throw new RootError({
      message: 'Infinite loop possible with improper values or usage',
      name: 'determineYAxisOffsetError',
    });
  }
  let yTickCount = 3;
  while (maxYDisplayValue / yTickCount % CLEAN_OFFSET_VALUE !== 0) {
    yTickCount++;
  }
  return maxYDisplayValue / yTickCount;
};

export { xAxisValues, maxYAxisValue, yAxisValues, closestHigherDivisible, determineYAxisOffset };
