import Colors from '@root/partners.joinroot.com/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import SkeletonLoaderBar from '@root/partners.joinroot.com/src/components/skeleton-loader-bar';
import { StyleSheet } from '@root/core/src/utils/styles';

const HeroItemSkeletonLoader = ({
  'aria-label': ariaLabel, displayFootnote, footnote, prefix, subtitle,
}) => {
  return (
    <div
      aria-label={`Loading ${ariaLabel}`}
      css={styles.skeletonWrapper}
    >
      <div css={styles.titleWrapper}>
        {prefix && <span css={styles.titlePrefix}>{prefix}</span>}
        <span css={styles.titleBar}>
          <SkeletonLoaderBar testID={`${ariaLabel} title`} />
        </span>
      </div>
      <div css={styles.subtitleWrapper}>
        <span css={styles.subtitle}>{subtitle}</span>
      </div>
      {displayFootnote &&
        <div css={styles.footnoteWrapper}>{footnote ?
          <span css={styles.footnote}>{footnote}</span> : <SkeletonLoaderBar testID={`${ariaLabel} footnote`} />}
        </div>
      }
    </div>
  );
};

HeroItemSkeletonLoader.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  displayFootnote: PropTypes.bool,
  footnote: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  prefix: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
};

export default HeroItemSkeletonLoader;

const styles = StyleSheet.create({
  skeletonWrapper: {
    paddingRight: 10,
  },
  titleWrapper: {
    height: 66,
    display: 'flex',
    alignItems: 'center',
    ...Responsive.lessThanMd({
      height: 45,
    }),
  },
  titlePrefix: {
    opacity: 0.6,
    marginRight: 15,
    fontSize: 44,
    fontWeight: 'bold',
    color: Colors.dashboardLightBlue(),
    ...Responsive.lessThanMd({
      fontSize: 30,
    }),
  },
  titleBar: {
    width: 150,
    display: 'inline-block',
  },
  subtitleWrapper: {
    marginTop: 7,
    height: 20,
    display: 'flex',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: 16,
    textTransform: 'uppercase',
    color: Colors.dashboardLightBlue(),
    fontWeight: 600,
    ...Responsive.lessThanMd({
      fontSize: 14,
    }),
  },
  footnoteWrapper: {
    marginTop: 15,
    height: 20,
    display: 'flex',
    alignItems: 'center',
  },
  footnote: {
    fontSize: 16,
    color: Colors.dashboardLightBlue(),
    opacity: 0.5,
    fontStyle: 'italic',
    ...Responsive.lessThanMd({
      fontSize: 14,
    }),
  },
});
