import { RootError } from '@root-common/root-errors';

export default class Money {
  static DOLLAR = 100;
  static THOUSAND_DOLLARS = 100000;

  static fromCents(totalInCents) {
    return new Money(totalInCents);
  }

  static fromDollars(totalInDollars) {
    return this.fromCents(Math.round(totalInDollars * 100));
  }

  constructor(totalInCents = 0) {
    if (!Number.isInteger(totalInCents)) {
      throw new RootError({
        message: `invalid Money argument '${totalInCents}', expected cents`,
        name: 'MoneyError',
        fingerprint: ['MoneyInvalidArgument'],
      });
    }
    this.totalInCents = totalInCents;
  }

  // formattedCents is intended to be used along with formattedDollars
  // and therefore if it is given a negative value it does not display the "-$".
  formattedCents() {
    return `.${this.format().split('.')[1]}`;
  }

  formattedDollars() {
    return this.format().split('.')[0];
  }

  formattedDollarsAndCents() {
    return this.format();
  }

  // smartFormat works just like formattedDollarsAndCents but cleans up the ".00" if found.
  smartFormat() {
    return this.format().replace(/\D00(?=\D*$)/, '');
  }

  formattedDollarsCeil() {
    return Money.fromDollars(Math.ceil(this._inDollars())).formattedDollars();
  }

  formattedKiloDollars() {
    const absoluteTotal = Math.abs(this.totalInCents);

    if (absoluteTotal > Money.THOUSAND_DOLLARS && absoluteTotal % Money.THOUSAND_DOLLARS === 0) {
      const thousandsOfDollars = this._inDollars() / 1000;
      return `${Money.fromDollars(thousandsOfDollars).formattedDollars()}K`;
    } else {
      return this.formattedDollars();
    }
  }

  format(formatterOptions = {}) {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      ...formatterOptions,
    }).format(this._inDollars());
  }

  _inDollars() {
    return this.totalInCents / Money.DOLLAR;
  }
}
