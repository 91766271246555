const INVALID_PASSWORD_MESSAGE = 'Password is not valid';

const isValidPassword = (fieldName) => (object, errors) => {
  const password = object[fieldName];

  const meetsLengthRequirement = password.length >= 10;
  const includesCapitalLetterRegex = /.*[A-Z].*/;
  const includesNumberRegex = /.*\d.*/;

  const validPassword = meetsLengthRequirement && includesCapitalLetterRegex.test(password) && includesNumberRegex.test(password);

  if (password && !validPassword) {
    errors[fieldName] = INVALID_PASSWORD_MESSAGE;
  }
};
export { isValidPassword };
