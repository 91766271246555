import Colors from '@root/partners.joinroot.com/src/utils/colors';
import PartnerUserContext from '@root/partners.joinroot.com/src/state/partner-user-context';
import PropTypes from '@root/vendor/prop-types';
import React, { useContext } from '@root/vendor/react';
import UserDashboardHeader from '@root/partners.joinroot.com/src/components/user-dashboard-header';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function PartnerUserWrapper({ children, onLogout = () => {} }) {
  const { partnerUserContext } = useContext(PartnerUserContext);

  return (
    <>
      <UserDashboardHeader
        onLogout={onLogout}
        username={partnerUserContext?.partnerName || '...'}
      />
      <div css={styles.container}>
        {children}
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100vh',
    backgroundColor: Colors.nearWhite(),
  },
});

PartnerUserWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onLogout: PropTypes.func,
};
