import PartnerUserApiNetworkRequestConfiguration from '@root/partners.joinroot.com/src/api/partner-user-api-network-request-configuration';

export default function emailFromTokenConfiguration(token) {
  return new PartnerUserApiNetworkRequestConfiguration({
    endpoint: 'emails/email_from_token',
    method: PartnerUserApiNetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      token,
    },
    errorCodes: [422],
  });
}
