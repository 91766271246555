import DashboardTable from '@root/partners.joinroot.com/src/components/dashboard-table';
import React, { useCallback, useState } from '@root/vendor/react';
import SkeletonLoaderTable from '@root/partners.joinroot.com/src/components/skeleton-loader-table';
import { useSubsiteEffectiveness } from '@root/partners.joinroot.com/src/hooks/use-partner-effectiveness';

function SubsiteEffectivenessTable() {
  const [subsiteEffectivenessData, setSubsiteEffectivenessData] = useState([]);
  const subsiteEffectivenessColumns = [{
    Header: 'Month (month)',
    accessor: 'month',
  }, {
    Header: 'SubSite ID (af_sub_siteid)',
    accessor: 'attributedAfSubsiteId',
  }, {
    Header: 'Traffic Effectiveness (traffic_effectiveness)',
    accessor: 'trafficEffectiveness',
  }];

  const isLoading = useSubsiteEffectiveness(useCallback((result) => {
    if (result.data?.subsiteEffectiveness) {
      setSubsiteEffectivenessData(result.data?.subsiteEffectiveness);
    }
  }, []));

  return (
    <div
      data-testid={'subsite-effectiveness-table'}
    >
      {
        isLoading ?
          <SkeletonLoaderTable
            testId={'subsite-effectiveness-table-skeleton'}
            title={'SubSite Effectiveness'}
          /> :
          <DashboardTable
            columns={subsiteEffectivenessColumns}
            csvNamePrefix={'root_subsite_effectiveness_table'}
            data={subsiteEffectivenessData}
            data-testid={'subsite-effectiveness-table'}
            title={'SubSite Effectiveness'}
          />
      }
    </div>
  );
}

export default React.memo(SubsiteEffectivenessTable);
