import PropTypes from '@root/vendor/prop-types';
import ProtectedRouter from '@root/partners.joinroot.com/src/components/protected-router';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import validateTokenConfiguration from '@root/partners.joinroot.com/src/api/validate-token/validate-token-configuration';
import { useEffect, useRef } from '@root/vendor/react';

const THIRTY_MINUTES_IN_MS = 1800000;
export default function ProtectedApp({ pingInterval = THIRTY_MINUTES_IN_MS, onLogout = () => {} }) {
  const { trackEvent } = useAnalytics('PROTECTED_APP');

  const [validateToken] = useImperativeNetworkRequest(validateTokenConfiguration);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  });

  useEffect(() => {
    const pingForToken = async () => {
      const validationResponse = await validateToken();

      if (!validationResponse.data.valid && isMounted.current) {
        trackEvent('LOGOUT_DUE_TO_INVALID_TOKEN');
        onLogout();
      }
    };

    const interval = setInterval(pingForToken, pingInterval);
    return () => clearInterval(interval);
  }, [pingInterval, onLogout, validateToken, trackEvent]);

  return <ProtectedRouter onLogout={onLogout} />;
}

ProtectedApp.propTypes = {
  onLogout: PropTypes.func.isRequired,
  pingInterval: PropTypes.number,
};
