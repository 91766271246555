import Colors from '@root/partners.joinroot.com/src/utils/colors';
import EventBreakdownTable from '@root/partners.joinroot.com/src/components/event-breakdown-table';
import React, { useCallback, useState } from '@root/vendor/react';
import SkeletonLoaderTable from '@root/partners.joinroot.com/src/components/skeleton-loader-table';
import usePartnerEventBreakdowns from '@root/partners.joinroot.com/src/hooks/use-partner-event-breakdowns';
import { StyleSheet } from '@root/core/src/utils/styles';

function BreakdownTables() {
  const [eventBreakdownsData, setEventBreakdownsData] = useState({
    eventBreakdown: [],
    discrepancyBreakdown: [],
  });

  const isLoading = usePartnerEventBreakdowns(useCallback((result) => {
    if (result.data) {
      setEventBreakdownsData(result.data);
    }
  }, []));

  return (
    <div>
      <div
        css={styles.table}
        data-testid={'event-breakdown-table'}
      >
        {isLoading ?
          <SkeletonLoaderTable
            testId={'event-breakdown-skeleton-loader-table'}
            title={'Detailed Event Breakdown'}
          /> :
          <EventBreakdownTable eventBreakdownData={eventBreakdownsData?.eventBreakdown} />
        }
      </div>
      <div
        css={styles.table}
        data-testid={'discrepancy-breakdown-table'}
      >
        {isLoading ?
          <SkeletonLoaderTable
            testId={'discrepancy-breakdown-skeleton-loader-table'}
            title={'Discrepancy Breakdown'}
          /> :
          <EventBreakdownTable
            eventBreakdownData={eventBreakdownsData?.discrepancyBreakdown}
            isDiscrepancyData={true}
          />
        }
      </div>
      <div css={styles.footnote}>
        {'*A 1-10 indicator of effectiveness provided as an insight. Does not match the RQI scale. This score is available for profiles 02-01-21 onwards. It can take up to 2 days for a profile to display a score.'}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  table: {
    marginTop: 15,
  },
  footnote: {
    color: Colors.gray50(),
    fontSize: '12px',
    marginTop: '20px',
    textAlign: 'center',
  },
});

export default React.memo(BreakdownTables);
