import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function PartnerLoginContainer({ subtext, children }) {
  return (
    <div css={styles.main}>
      <div css={styles.container}>
        <div css={styles.subtext}>
          {subtext}
        </div>
        <div css={styles.line} />
        {children}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  main: {
    width: 424,
    background: Colors.white(),
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 15,
    marginTop: 30,
    borderRadius: 4,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.19)',
    ...Responsive.lessThanSm({
      width: '100%',
      marginTop: 0,
      boxShadow: 'none',
    }),
  },
  subtext: {
    ...Theme.input(),
    fontWeight: 600,
  },
  line: {
    borderBottom: `1px solid ${Colors.rootOrange()}`,
    width: 74,
    paddingTop: 20,
    marginBottom: 20,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: 47,
    paddingRight: 47,
    paddingTop: 15,
    ...Responsive.lessThanSm({
      paddingLeft: 18,
      paddingRight: 18,
    }),
  },
});

PartnerLoginContainer.propTypes = {
  children: PropTypes.node,
  subtext: PropTypes.string,
};
