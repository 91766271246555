import ArrowRight from '@root/partners.joinroot.com/src/assets/arrow-right';
import PartnerPortalButton from '@root/partners.joinroot.com/src/components/partner-portal-button';
import propTypes from '@root/vendor/prop-types';
import { BUTTON_ICON_POSITION_RIGHT } from '@root/core/src/components/button';

const NextButton = ({
  onClick, disabled, children,
}) => (
  <PartnerPortalButton
    disabled={disabled}
    icon={ArrowRight}
    iconPosition={BUTTON_ICON_POSITION_RIGHT}
    onClick={onClick}
  >
    {children}
  </PartnerPortalButton>
);

NextButton.propTypes = {
  children: propTypes.node.isRequired,
  disabled: propTypes.bool,
  onClick: propTypes.func,
};

export default NextButton;
