import PartnerUserContext from '@root/partners.joinroot.com/src/state/partner-user-context';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import usePartnerUserContext from '@root/partners.joinroot.com/src/hooks/use-partner-user-context';

export default function PartnerUserContextProvider({ children }) {
  const { isLoading, partnerUserContext } = usePartnerUserContext();

  return (
    <PartnerUserContext.Provider
      value={{
        partnerUserContext,
        isLoading,
      }}
    >
      {children}
    </PartnerUserContext.Provider>
  );
}

PartnerUserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
