import { StyleSheet, Theme } from '@root/core/src/utils/styles';

const PasswordRequirements = () => (
  <div css={styles.passwordRequirements}>
    Password must be at least 10 characters, must contain a capital letter and a number.
  </div>
);

export default PasswordRequirements;

const styles = StyleSheet.create({
  passwordRequirements: {
    ...Theme.paragraph2({
      italic: true,
    }),
  },
});
