import HeroItem from '@root/partners.joinroot.com/src/components/hero-item';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default function RqiHeroItem({ rqi }) {
  return (
    <HeroItem
      header={rqi}
      isDecimalValue={true}
      isMonetaryValue={false}
      noDataMessage={'Sorry, no data to display here at the moment'}
      subtitle={'*avg. root quality indicator this month'}
      testID={'rqi-hero-item'}
      tooltipAvailable={true}
      tooltipContent={
        <span>Root pays for profiles based on your average overall monthly quality, as determined by the Root Quality Indicator (RQI), and is subject to our standardized monthly internal review process.</span>
      }
    />
  );
}

RqiHeroItem.propTypes = {
  rqi: PropTypes.number,
};
