import ChartTooltip from '@root/partners.joinroot.com/src/components/bar-chart/chart-tooltip';
import Colors from '@root/partners.joinroot.com/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import dayjs from '@root/vendor/dayjs';
import useResize from '@root/core/src/hooks/use-resize';
import { ResponsiveBar } from '@root/vendor/@nivo/bar';
import { StyleSheet } from '@root/core/src/utils/styles';
import { closestHigherDivisible, determineYAxisOffset, maxYAxisValue, xAxisValues, yAxisValues } from '@root/partners.joinroot.com/src/utils/chart-axis-utils';
import { useLoadingData } from '@root/partners.joinroot.com/src/utils/bar-chart-utils';
import { useState } from '@root/vendor/react';

const X_AXIS_OFFSETS = {
  smallScreen: 15,
  mediumScreen: 10,
  largeScreen: 7,
};

const CampaignChart = ({
  data, legendValues, isLoading,
}) => {
  const determineXAxisOffset = () => {
    if (Responsive.matches(Responsive.QUERIES.lessThanMd)) {
      return X_AXIS_OFFSETS.smallScreen;
    } else if (Responsive.matches(Responsive.QUERIES.lessThanLg)) {
      return X_AXIS_OFFSETS.mediumScreen;
    }
    return X_AXIS_OFFSETS.largeScreen;
  };
  const checkSmallScreen = () => { return Responsive.matches(Responsive.QUERIES.lessThanXl); };
  const [isSmallScreen, setIsSmallScreen] = useState(checkSmallScreen);
  const [xAxisOffset, setXAxisOffset] = useState(determineXAxisOffset);
  useResize({
    onResize: () => {
      setIsSmallScreen(checkSmallScreen);
      setXAxisOffset(determineXAxisOffset);
    },
  });

  const updatedGraphData = useLoadingData({
    data,
    isLoading,
  });

  const maxYDisplayValue = closestHigherDivisible(Math.ceil(maxYAxisValue(data)));

  const yAxisOffset = isLoading ? 10 : determineYAxisOffset(maxYDisplayValue);
  const yAxisGridOffset = isLoading ? 2 : yAxisOffset / 5;

  const yAxisUpperBoundary = isLoading ? 100 : maxYDisplayValue;
  const yAxisTickValues = yAxisValues(yAxisOffset, yAxisUpperBoundary);
  const yAxisGridValues = yAxisValues(yAxisGridOffset, yAxisUpperBoundary);

  const legendPositioning =
    isSmallScreen ?
      {
        anchor: 'bottom',
        direction: 'row',
        translateY: 70,
      } :
      {
        anchor: 'bottom-right',
        direction: 'column',
        translateX: 120,
      };

  return (
    <div
      css={[styles.graphContainer, isLoading ? styles.loadingFade : null]}
      data-testid={'bar-chart'}
    >
      <ResponsiveBar
        animate={true}
        axisBottom={{
          tickValues: xAxisValues(data, xAxisOffset),
          tickPadding: 10,
          format: (date) => `${dayjs(date).format('MMM DD').toUpperCase()}`,
        }}
        axisLeft={{
          tickValues: yAxisTickValues,
          tickSize: 0,
          tickPadding: 20,
        }}
        colors={[isLoading ? Colors.gray50() : Colors.primaryDashboardBlue(), Colors.secondaryDashboardBlue(), Colors.tertiaryDashboardBlue()]}
        data={updatedGraphData}
        enableLabel={false}
        gridYValues={yAxisGridValues}
        indexBy={'date'}
        keys={legendValues}
        legends={[{
          ...legendPositioning,
          itemsSpacing: 10,
          itemWidth: 100,
          itemHeight: 20,
          symbolSize: 20,
          symbolShape: 'circle',
        }]}
        margin={{
          top: 10,
          right: isSmallScreen ? 10 : 130,
          bottom: isSmallScreen ? 130 : 80,
          left: 50,
        }}
        maxValue={yAxisUpperBoundary}
        minValue={0}
        motionDamping={20}
        motionStiffness={90}
        padding={0.3}
        theme={{
          tooltip: {
            container: {
              padding: 0,
              margin: 0,
            },
          },
          legends: {
            text: {
              fill: Colors.white(),
            },
          },
          axis: {
            ticks: {
              text: {
                fill: Colors.dashboardLightBlue(),
                opacity: 0.5,
                fontSize: 12,
              },
            },
          },
          grid: {
            line: {
              stroke: Colors.gray50(),
              strokeWidth: 0.5,
              strokeDasharray: '1 3',
            },
          },
        }}
        tooltip={(input) => <div css={styles.tooltipContainer}><ChartTooltip input={input} /></div>}
      />
    </div>
  );
};

CampaignChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  legendValues: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const styles = StyleSheet.create({
  graphContainer: {
    width: '100%',
    height: '100%',

    '& line[stroke-width="0.5"]:nth-of-type(5n+6)': {
      strokeDasharray: 0,
    },
  },
  tooltipContainer: {
    color: Colors.black(),
  },
});

export default CampaignChart;
