import Dashboard from '@root/partners.joinroot.com/src/scenes/dashboard';
import Documentation from '@root/partners.joinroot.com/src/scenes/documentation';
import PartnerUserContextProvider from '@root/partners.joinroot.com/src/state/partner-user-context-provider';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Redirect, Route, Switch } from '@root/core/src/components/route';

const Routes = {
  DASHBOARD: '/dashboard',
  DOCUMENTATION: '/documentation',
};

export default function ProtectedRouter({ onLogout }) {
  return (
    <PartnerUserContextProvider>
      <Switch>
        <Route
          path={Routes.DASHBOARD}
          render={() => <Dashboard onLogout={onLogout} />}
        />
        <Route
          path={Routes.DOCUMENTATION}
          render={() => <Documentation onLogout={onLogout} />}
        />
        <Redirect to={'/dashboard'} />
      </Switch>
    </PartnerUserContextProvider>
  );
}

export { Routes };

ProtectedRouter.propTypes = {
  onLogout: PropTypes.func.isRequired,
};
